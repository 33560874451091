export const userMenu = [
 
   
  {
    name: "Home",
    path: "/",
    icon: "fa-solid fa-house",
  },

    {
      name: "Add Profile",
      path: "/apply-doctor",
      icon: "fa-solid fa-user-doctor",
    },
  
  ];
  
  // admin menu
  export const adminMenu = [
    {
      name: "Home",
      path: "/",
      icon: "fa-solid fa-house",
    },
  
    {
      name: "Doctors",
      path: "/admin/doctors",
      icon: "fa-solid fa-user-doctor",
    },
    {
      name: "Users",
      path: "/admin/users",
      icon: "fa-solid fa-user",
    },
    {
      name: "Profile",
      path: "/profile",
      icon: "fa-solid fa-user",
    },
  ];

  