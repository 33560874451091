import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

import axios from "axios";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../redux/features/alertSlice";
import AppointmentSearch from "./AppointmentSearch";
import "../styles/homepage.css";



import { SearchOutlined } from '@ant-design/icons';

import DatePicker from 'react-datepicker';



import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useParams, useNavigate } from "react-router-dom";


import { message, Form, Input, Row, Col, Button, Space, Select, InputNumber,Divider,Card, Descriptions, Spin ,Tabs} from 'antd';






const { TabPane } = Tabs;
const HomePage = () => {


  const [, forceUpdate] = useState();
  
  const { user } = useSelector((state) => state.user);
  const [vaccineData, setVaccineData] = useState([]);
  
  const [bookingDate, setBookingDate] = useState("");
  const [vaccineDate, setVaccineDate] = useState("");
  const [vaccineDue, setDueDate] = useState("");
  const [initialBirthDate, setInitialBirthDate] = useState(null);

  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const params = useParams();
  const [clinicemail, setClinicemail] = useState('');
  const dispatch = useDispatch();
  const [patientId, setPatientId] = useState('');
  const [name, setName] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { TextArea } = Input;

  const [vaccineDates, setVaccineDates] = useState([]);

  const [vaccinationData, setVaccinationData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/dashboard');
  }, [navigate]);

  

  const handleVaccineDateChange = (index, dateValue, isDueDate = false) => {
    const newVaccineDates = [...vaccineDates];
    if (!newVaccineDates[index]) {
      newVaccineDates[index] = { vaccineDate: null, vaccineDue: null };
    }
    if (isDueDate) {
      newVaccineDates[index].vaccineDue = dateValue;
    } else {
      newVaccineDates[index].vaccineDate = dateValue;
    }
    setVaccineDates(newVaccineDates);
  };


  const getTodayRange = () => {
    return {
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString()
    };
  };
  
  const getTomorrowRange = () => {
    return {
      startDate: moment().add(1, 'days').startOf('day').toISOString(),
      endDate: moment().add(1, 'days').endOf('day').toISOString()
    };
  };
 

  const getNextWeekRange = () => {
    return {
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().add(7, 'days').endOf('day').toISOString()
    };
  };



  const fetchVaccinationData = async (rangeType) => {
    let range;
    if (rangeType === 'today') {
      range = getTodayRange();
    } else if (rangeType === 'tomorrow') {
      range = getTomorrowRange();
    } else if (rangeType === 'nextWeek') {
      range = getNextWeekRange();
    }
  
    try {
      const response = await axios.get('/api/v1/user/vaccinations-due', {
        params: {
          startDate: range.startDate,
          endDate: range.endDate,
          clinicemail: doctor?.clinicemail
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
          setVaccinationData(response.data.data);
      } else {
          console.error('Failed to fetch data');
          setVaccinationData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setVaccinationData(null);
    }
  };
  

useEffect(() => {
  fetchVaccinationData();
}, []);


 





  const handleSearch = async () => {
    console.log("Search Term:", searchTerm);
    if (!doctor?.clinicemail) {
      message.error("Doctor information is missing.");
      return;
    }
  
    try {
      
      const response = await axios.get(`/api/v1/user/patient`, {
        params: { searchTerm: searchTerm, clinicemail: doctor.clinicemail,},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log('Received data:', response.data); // Temporary log for debugging

      // Check if data is received
      if (response.data && response.data.success) {
        const patients = response.data.data;
       
        // Assuming the search might return multiple patients
        if (patients.length > 0) {
          const patientWithFormattedDate = {
            ...patients[0],
            birthDate: patients[0].birthDate ? moment(patients[0].birthDate).format('YYYY-MM-DD') : null, // Convert other date fields similarly
          };
          
          setPatientData(patients[0]); // Here, taking the first patient as an example
          form.setFieldsValue(patients[0]);
        } else {
          message.info('No patient found with the provided ID or phone number.');
          setPatientData(null);
        }
      } else {
        message.error('Failed to search for patient.');
        setPatientData(null);
      }
    } catch (error) {
      console.error('Error during patient search:', error);
      message.error('Failed to search for patient.');
      setPatientData(null);
    }
  };
  




  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
  
       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    
    //eslint-disable-next-line
  }, []);
 
 
  const handleUpdatePatient = async (values) => {
    console.log("Form submit values:", values);
    console.log("update Term:", searchTerm);
    if (!patientData || !doctor?.clinicemail) {
      message.error("Required data is missing.");
      return;
    }
  
    try {
      const response = await axios.put(`/api/v1/user/updatePatient/${patientData.patientId}/${doctor.clinicemail}`, values,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
        message.success('Patient updated successfully.');
        // Refresh or redirect logic here
      } else {
        message.error('Failed to update patient: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error during patient update:', error);
      message.error('Failed to update patient.');
    }
  };



  const fetchPatientData = async () => {
    try {
      const res = await axios.get(
        "/api/v1/user/patientss", // Update this endpoint to the correct one
           // Assuming you want to record which user added the patient
        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setPatient(res.data.data);

       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPatientData();
    //eslint-disable-next-line
  }, []);


  return (
    <div className="body">
    <Layout>
    

    <div className="welcome-container">
      <nav className="navbar">
        <div className="logo">Vet Alertr</div>
        <div className="nav-links">
          <a href="/services">Services</a>
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
        </div>
        <div className="auth-buttons">
         
        </div>
      </nav>
      <div className="main-content">
        <h1>Welcome to Vet Alertr</h1>
        <p>Your trusted source for pet health and veterinary services.</p>
        <img src="path_to_your_pet_image.jpg" alt="Happy Pets" />
      </div>
    </div>

  </Layout>
  </div>
  );
};



export default HomePage;