import React, { useEffect,useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../redux/features/alertSlice";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { message, Form, Input, Row, Col, Button, Space, DatePicker, Select, InputNumber,Divider,Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "./../../styles/AddPatient.css";
import InjectionForm from "./InjectionForm";

import Sidebar from '../Sidebar';
import yourImageUrl from '../../images/allgr.png';
import { AppBar, Toolbar, InputBase, Paper, Fab, CardContent, Typography, Badge, IconButton, Box, CssBaseline, Drawer, Grid,CardActions,useTheme, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Mail as MailIcon, Menu as MenuIcon, CalendarToday as CalendarIcon, TableView as TableIcon, PersonAdd as AddPatientIcon  } from '@mui/icons-material';
const drawerWidth = 240;


const AddPatient = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [doctor, setDoctor] = useState(null);
  const params = useParams();
  const [selectedInjection, setSelectedInjection] = useState(null);
  const { Option } = Select;
  const { TextArea } = Input;
  const [location, setLocation] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [species, setSpecies] = useState('');
  const [clinicNumber, setClinicNumber] = useState('');
  const [clinicemail, setClinicemail] = useState('');
  const [fields, setFields] = useState([]);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);

  const [birthDate, setBirthDate] = useState(null);
  const [dateOfVaccination, setDateOfVaccination] = useState(null);
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);





  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const modernSoothingGreen = '#029d49';


  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
const generatePatientId = () => {
  const timestamp = Date.now().toString();
  const randomPortion = Math.random().toString(36).substring(2, 7); // random string of 5 characters
  const patientId = `P-${timestamp}-${randomPortion}`.toUpperCase();

  return patientId;
};

const handleSpeciesChange = (value) => {
  setSpecies(value);
};


const dogBreeds = [
  "Labrador Retriever",
  "German Shepherd",
  "Golden Retriever",
  "French Bulldog",
  "Bulldog",
  "Poodle",
  "Beagle",
  "Rottweiler",
  "German Shorthaired Pointer",
  "Yorkshire Terrier",
  "Boxer",
  "Siberian Husky",
  "Dachshund",
  "Great Dane",
  "Doberman Pinscher",
  "Australian Shepherd",
  "Cavalier King Charles Spaniel",
  "Shih Tzu",
  "Boston Terrier",
  "Pug",
  "Akita",
  "Alaskan Malamute",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Staffordshire Terrier",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Basenji",
  "Basset Hound",
  "Bernese Mountain Dog",
  "Bloodhound",
  "Border Collie",
  "Borzoi",
  "Brittany",
  "Bull Terrier",
  "Cairn Terrier",
  "Cane Corso",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Collie",
  "Dalmatian",
  "English Cocker Spaniel",
  "English Setter",
  "English Springer Spaniel",
  "Flat-Coated Retriever",
  "Fox Terrier",
  "Giant Schnauzer",
  "Greyhound",
  "Irish Setter",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "Komondor",
  "Kuvasz",
  "Lhasa Apso",
  "Maltese",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Neapolitan Mastiff",
  "Newfoundland",
  "Norwegian Elkhound",
  "Old English Sheepdog",
  "Papillon",
  "Pekingese",
  "Pit Bull",
  "Pointer",
  "Rhodesian Ridgeback",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Scottish Terrier",
  "Shar Pei",
  "Shetland Sheepdog",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Tibetan Mastiff",
  "Vizsla",
  "Weimaraner",
  "Welsh Corgi",
  "West Highland White Terrier",
  "Whippet",
  "Wirehaired Pointing Griffon",
  "Indian Pariah Dog",
  "Mudhol Hound",
  "Rajapalayam",
  "Kanni",
  "Chippiparai",
  "Gaddi Kutta",
  "Kumaon Mastiff",
  "Bakharwal Dog",
  "Rampur Greyhound",
];

const catBreeds = [
  // ...list of cat breeds...
  "Abyssinian",
  "Bengal",
  "Abyssinian",
  "Bengal",
  "Persian",
  "Maine Coon",
"Persian",
"Siamese",
"Ragdoll",
"Abyssinian",
"Sphynx",
"American Shorthair",
"British Shorthair",
"Russian Blue",
"Oriental Shorthair",
"Himalayan",
  // ...other cat breeds...
];

const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, c => c.toUpperCase()); // Capitalize the first letter of each word
};

const handleOwnerNameChange = (event) => {
  const capitalized = capitalizeFirstLetter(event.target.value);
  setOwnerName(capitalized);
};

const handleGetCurrentLocation = () => {
  if (navigator.geolocation) {
    setIsFetchingLocation(true); // Start loading
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      setLocation(googleMapsUrl);
      form.setFieldsValue({ location: googleMapsUrl });
      setIsFetchingLocation(false); // Stop loading
    }, () => {
      message.error('Unable to retrieve your location');
      setIsFetchingLocation(false); // Stop loading
    });
  } else {
    message.error('Geolocation is not supported by this browser.');
    setIsFetchingLocation(false); // Stop loading
  }
};

useEffect(() => {
  console.log("Updated Location State:", location);
}, [location]);

  const handleFinish = async (values) => {
    const patientId = generatePatientId();

    const breedValueToSend = values.breed === 'Other' ? values.otherBreed : values.breed;
    // Append the +91 prefix to the phone number
    const phoneNumberWithPrefix = `91${values.phoneNumber}`;
    const locationValue = location || values.location;
    try {
      dispatch(showLoading());

        const patientId = generatePatientId();
      const response = await axios.post(
        "/api/v1/user/add-patient", // Update this endpoint to the correct one
        {
          ...values,
           patientId,
           breed: breedValueToSend,
           phoneNumber: phoneNumberWithPrefix, // Use the modified phone number
           location: location,
           clinicNumber: clinicNumber, 
          clinicemail: clinicemail,  injection: selectedInjection,
          addedBy: user._id,
          firstName: doctor.firstName,// Assuming you want to record which user added the patient
        },  // Debug log
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        message.success("Patient added successfully");
        console.log('Premium:', doctor?.premium);

        if (doctor && doctor.premium) {
          sendWhatsAppMessagesBasedOnVaccinationRecords(values,);
        }
        setLocation('');
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      message.error("Something went wrong while adding the patient");
    }
  };

  const determineTemplateName = (species, vaccineName) => {
    if (species === 'dog' && vaccineName === 'Multi Component Vaccine') {
      return 'dogmcv';
    } else if (species === 'dog' && vaccineName === 'Anti Rabies Vaccine') {
      return 'donerabies';
    } else if (species === 'cat' && vaccineName === 'Multi Component Vaccine') {
      return 'catmcv';
    } else if (species === 'cat' && vaccineName === 'Anti Rabies Vaccine') {
      return 'donerabies';
    }
    return ''; // Default case, though you should handle this appropriately
  };

  const sendWhatsAppMessagesBasedOnVaccinationRecords = (values, patientId, doctorClinicEmail) => {
    // Ensure vaccinationHistory is an array before proceeding
    if (!values.vaccinationHistory || !Array.isArray(values.vaccinationHistory)) {
      console.error('vaccinationHistory is undefined or not an array');
      return;
    }
  
    const today = moment().startOf('day');
    values.vaccinationHistory.forEach(record => {
      const vaccinationDate = moment(record.dateOfVaccination);
      if (vaccinationDate.isSame(today, 'day')) {
        const templateName = determineTemplateName(values.species, record.vaccineName);
        if (!templateName) {
          console.log('No template name determined for record:', record);
          return;
        }
        const templateVariables = [
          values.name,
          vaccinationDate.format('DD-MM-YYYY'),
          doctor.firstName,
          doctor.clinicNumber,
        ];
        updateMessageCount(clinicemail);
        sendWhatsAppMessage(values.phoneNumber, templateName, templateVariables, patientId, doctorClinicEmail);
      }
    });
  };

  const sendWhatsAppMessage = async (phoneNumber, templateName, templateVariables, patientId, clinicEmail) => {
    const requestData = {
      phoneNumber,
      templateName,
      templateVariables,
    };
    console.log('Sending WhatsApp message with:', { phoneNumber, templateName, templateVariables });

    try {
      const response = await axios.put(`/api/v1/user/sendsms`, requestData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
        console.log('WhatsApp message sent successfully.');
       
      } else {
        console.error(`Failed to send WhatsApp message: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error during WhatsApp message sending:', error);
    }
  };

  const updateMessageCount = async (clinicemail) => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; // getMonth() returns month from 0-11
    const successfulNumber = "exampleNumber"; // Placeholder, use actual successful number
  
    console.log('Sending data to backend:', { clinicemail, year, month, successfulNumber });

    try {
      const response = await axios.post('/api/v1/user/counter', {
        clinicemail: clinicemail,
        year: year,
        month: month,
        successfulNumber: successfulNumber
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      console.log('Response from backend:', response.data);
      console.log('Message count updated successfully.');
    } catch (error) {
      console.error('Failed to update message count:', error);
    }
  };

  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
        setClinicNumber(res.data.data.clinicNumber); 
        setClinicemail(res.data.data.clinicemail); 
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    //eslint-disable-next-line
  }, []);
 

  useEffect(() => {
    if (birthDate && dateOfVaccination) {
      const oneYearAgo = moment().subtract(1, 'years');
      if (birthDate.isBefore(oneYearAgo)) {
        const nextDueDate = dateOfVaccination.clone().add(1, 'years');
        // Here, update the form field for nextDueDate
        form.setFieldsValue({
          vaccinationHistory: form.getFieldValue('vaccinationHistory').map(vh => ({
            ...vh,
            nextDueDate: vh.dateOfVaccination ? moment(vh.dateOfVaccination).add(1, 'years') : undefined,
          })),
        });
      }
    }
  }, [birthDate, dateOfVaccination, form]);
  

  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Box sx={{

      flexGrow: 1,
      '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%', // Adjust the height as needed
        backgroundColor: '#f0f2f5',
        zIndex: -1
      },
      position: 'relative', // Ensure that the pseudo-element is positioned relative to this Box
    }}>
<AppBar position="fixed" elevation={0} sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent', backdropFilter: 'blur(0px)' }}>
<Toolbar sx={{ 
        display: 'flex', 
        justifyContent: 'space-between' // This will push the menu icon to the left and search bar to the right
      }}>
             <IconButton color="inherit"  onClick={handleDrawerToggle} sx={{ mr: 'auto' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" sx={{ 
    mb: 4, 
fontWeight:'900',
    background: 'white',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1rem',
  }}>
{user?.name}
  </Typography>
   
        </Toolbar>
      </AppBar>
      <Box sx={{
         flexGrow: 1,
      p: isXsDown ? 2 : 3, // Responsive padding, smaller on extra-small screens
      pb: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '30vh', // Background image heightge
        backgroundRepeat: "repeat" ,
        backgroundImage: `url(${yourImageUrl})`, // Your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1, // Behind the content
      }} />
{isMdUp ? (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
) : (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
)}   <Toolbar /> 

<div className="patient-form-container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      
<h1>Add Patient</h1>
      <Form layout="vertical" onFinish={handleFinish} className="patient-form" form={form}>
        {/* Basic Patient Details */}
        <Form.Item  name="name" label="Name" rules={[{ required: true, message: 'Please input the patient\'s name!' }]}>
          <Input placeholder="Patient's Name"  style={{ width: '100%' }} value={ownerName} />
        </Form.Item>
        <Form.Item name="ownername" label="Owner Name" rules={[{ required: true, }]}>
          <Input placeholder="Owner's Name"  style={{ width: '100%' }}      value={ownerName}
              onChange={handleOwnerNameChange}/>
        </Form.Item>

        <Form.Item name="species" label="Species" rules={[{ required: true }]}>
          <Select placeholder="Select species" onChange={handleSpeciesChange}>
            <Option value="dog">Dog</Option>
            <Option value="cat">Cat</Option>
            {/* Add more species options */}
          </Select>
        </Form.Item>


        <Form.Item name="breed" label="Breed" rules={[{ required: false }]}>
  <Select
    showSearch
    placeholder="Select or type a breed"
    optionFilterProp="children"
    notFoundContent={null}
  >
    {species === 'dog' ? (
      dogBreeds.map(breed => (
        <Option key={breed} value={breed}>
          {breed}
        </Option>
      ))
    ) : (
      catBreeds.map(breed => (
        <Option key={breed} value={breed}>
          {breed}
        </Option>
      ))
    )}
    <Option value="Other">Other</Option> {/* Add an "Other" option */}
  </Select>
</Form.Item>

<Form.Item
  noStyle
  shouldUpdate={(prevValues, currentValues) => prevValues.breed !== currentValues.breed}
>
  {({ getFieldValue }) => {
    const selectedBreed = getFieldValue('breed');
    if (selectedBreed === 'Other') {
      return (
        <Form.Item
        name="otherBreed"
          label="Other Breed"
          rules={[{ required: true, message: 'Please input the breed!' }]}
        >
          <Input style={{ width: '100%' }}  placeholder="Type the breed name" />
        </Form.Item>
      );
    }
    return null;
  }}
</Form.Item>
        <Form.Item name="birthDate" label="Birth Date">
          <DatePicker  />
        </Form.Item>
        <Form.Item name="weight" label="Weight (kg)">
          <InputNumber style={{ width: '100%' }}  />
        </Form.Item>
        <Form.Item
  name="phoneNumber"
  label="Phone Number"
  rules={[
    { 
      required: true, 
      message: "Please input the patient's Phone Number!" 
    },
    { 
      len: 10, 
      message: 'Phone number must be 10 digits long!',
    },
  ]}
>
  <Input 
    addonBefore="91" 
    style={{ width: '100%' }} 
    type="tel"
    pattern="\d*"
  />
</Form.Item>
<Form.Item label="Location">
  <Row gutter={8} align="middle">
    <Col flex="auto">
      <Input 
      style={{ width: '100%' }}
        value={location}
        onChange={e => setLocation(e.target.value)}
        placeholder="Type place or use current location"
      />
    </Col>
    <Col>
    <Button onClick={handleGetCurrentLocation} disabled={isFetchingLocation}>
  {isFetchingLocation ? <Spin /> : 'Use Current Location'}
</Button>
    </Col>
  </Row>
</Form.Item>

{location && (
  <Form.Item>
    <Button 
      type="primary" className="btn"
      onClick={() => window.open(location, '_blank')}
    >
      Open in Google Maps
    </Button>
  </Form.Item>
)}




        <Divider orientation="left">Vaccination History</Divider>
<Form.List name="vaccinationHistory">
  {(fields, { add, remove }) => (
    <>
      {fields.map(field => (
        <div key={field.key} className="field-container">
          <Space direction="vertical" className="space-align-container">
            <Form.Item
              {...field}
              
              name={[field.name, 'vaccineName']}
              fieldKey={[field.fieldKey, 'vaccineName']}
              rules={[{ required: true, message: 'Vaccine name required' }]}
              label="Vaccine Name"
            >
              <Select placeholder="Select Vaccine">
            <Option value="Multi Component Vaccine">Multi Component Vaccine</Option>
            <Option value="Anti Rabies Vaccine">Anti Rabies Vaccine</Option>
            {/* Add more species options */}
          </Select>
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, 'dateOfVaccination']}
              fieldKey={[field.fieldKey, 'dateOfVaccination']}
              rules={[{ required: false, message: 'Date of vaccination required' }]}
              label="Date of Vaccination"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, 'nextDueDate']}
              fieldKey={[field.fieldKey, 'nextDueDate']}
              label="Next Due Date"
            >
              <DatePicker />
            </Form.Item>
            <MinusCircleOutlined className="minus-circle-icon" onClick={() => remove(field.name)} />
          </Space>
        </div>
      ))}
      <Button type="dashed" className="ant-btn-dashed" onClick={() => add()} icon={<PlusOutlined />}>
        Add Vaccination Record
      </Button>
    </>
  )}
</Form.List>


        {/* Medical History and Treatments */}
       
        <Button type="primary" className="btn" htmlType="submit">Add Patient</Button>
  </Form>
   
      {doctor && (
        <Form
          layout="vertical"
          onFinish={handleFinish}
          className="m-3"
          initialValues={{
            ...doctor,
            

             
          }}
        >

            </Form>
            )}
      </div>
      </Box>
    </Box>
  );
};

export default AddPatient;