import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store";
import { CssBaseline } from '@mui/material';
import { AuthProvider } from './pages/AuthContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
  <Provider store={store}>
    <React.StrictMode>
    <CssBaseline />
      <App />
    </React.StrictMode>
  </Provider>
</AuthProvider>

);


reportWebVitals();
