import React, { useState, useEffect } from "react";
import '../styles/sidebar.css';
import { adminMenu, userMenu } from "../Data/data";
import alert from '../images/1.png';

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, message } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SizeContext from "antd/es/config-provider/SizeContext";
const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  // logout funtion
  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successfully");
    navigate("/login");
  };
  const handleBack = () => {
    navigate(-1); // Go back in history
  };
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };


  // =========== doctor menu ===============
  const doctorMenu = [

  
    {
      name: "Dashboard",
      path: "/vaccine",
      icon: "fa-solid fa-home",
    },
   
    {
      name: "Add Patient",
      path: "/AddPatient",
      icon: "fa-solid fa-paw",
    },
    // New menu item for searching patients
    {
      name: "Search Patient",
      path: "/search",
      icon: "fa-solid fa-search",
    },
     {
      name: "Profile",
      path: `/doctor/profile/${user?._id}`,
      icon: "fa-solid fa-user",
    },
 
   
    
  ];
  // =========== doctor menu ===============

  // redering menu list
  const SidebarMenu = user?.isAdmin
    ? adminMenu
    : user?.isDoctor
    ? doctorMenu
    : userMenu;
  return (
    <>
      <div className="body">

  

        <div className={`sidebar ${sidebarOpen ? 'active' : 'inactive'}`}>
        <button className={`sidebar-toggle ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
          <i className="fa-solid fa-close"></i>
        </button>

            <div className="logo">
         
        <img src={alert} alt="Name"  className="feature-image" />
  
      
              <hr />
            </div>

  
            <div className="logo">
              <h6 className="text-darks">{user?.name}</h6>
              <hr />
            </div>
       
            <div className="sidebar-menu">
              {SidebarMenu.map((menu) => {
                const isActive = location.pathname === menu.path;
                return (
                  <>
                    <div className={`sidebar-item ${isActive && "active"}`}>
                      <i className={menu.icon}></i>
                      <Link to={menu.path}>{menu.name}</Link>
                    </div>
                  </>
                );
              })}
              
              <div className={`sidebar-item`} onClick={handleLogout}>
                <i className="fa-solid fa-right-from-bracket"></i>
                <Link to="/login">Logout</Link>
              </div>
            </div>
          </div>
        
  
     <div className="body">{children}</div>
      </div>
      
    </>
  );
};

export default Layout;