import React, { useState, useEffect } from "react";
import '../styles/front.css';
import '../styles/sidebar.css'; // Link to your CSS file
import userImage from '../images/couplecat.png';
import doccall from '../images/doccall.png';
import docfind from '../images/docfind.png';
import Sidebar from './Sidebar'; // Adjust the path according to your file structure
import { useSelector } from "react-redux";



function Front() {
  const { user } = useSelector((state) => state.user);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const [activeTab, setActiveTab] = useState('cats');
  return (



    <div className={`app ${isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      

      <button onClick={toggleSidebar} className="sidebar-toggle">
{isSidebarVisible ? '<' : '>'} {/* Replace with icons or SVG if needed */}
      </button>

      {isSidebarVisible && <Sidebar />}


      <header className="app-header">
        <div className="user-greeting">
         
       
        </div>
        <div className="heading">
         
         <h3>Pet Care Pro</h3>
       </div>
   
      </header>
  
      <div className="feature-grid">
  {/* ... other buttons ... */}
  <div className="special-feature-row">
    <div className="feature-item">
      <button className="feature-button">
        <img src={doccall} alt="Call Doctor" className="feature-image" />
      </button>
      <button className="feature-buttons">
      <span className="feature-description">Call Doctor</span>
      </button>
 
    </div>
    <div className="feature-item">
      <button className="feature-button">
        <img src={doccall} alt="Call Doctor" className="feature-image" />
      </button>
      <button className="feature-buttons">
      <span className="feature-description">Book Vaccination</span>
      </button>
 
    </div>
    <div className="feature-item">
      <button className="feature-button">
        <img src={doccall} alt="Call Doctor" className="feature-image" />
      </button>
      <button className="feature-buttons">
      <span className="feature-description"> Book Clinic </span>
      </button>
 
    </div>
    <div className="feature-item">
      <button className="feature-button">
        <img src={doccall} alt="Call Doctor" className="feature-image" />
      </button>
      <button className="feature-buttons">
      <span className="feature-description"> Book Doctor </span>
      </button>
 
    </div>
    
      
 
  </div>
</div>




      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'cats' ? 'active' : ''}`}
          onClick={() => setActiveTab('cats')}
        >  Cats
        </button>
        <button
          className={`tab-button ${activeTab === 'dogs' ? 'active' : ''}`}
          onClick={() => setActiveTab('dogs')}
        >
          Dogs
        </button>
      </div>
      {activeTab === 'cats' && (
      <section className="category">
      <h2>Cats</h2>
      <div className="category-actions">
        <button className="category-button">
          <img src={userImage} alt="Find a Mate" />
          Find a Mate
        </button>
        <button className="category-button">
          <img src={userImage} alt="Find a Kitten" />
          Find a Kitten
        </button>
        <button className="category-button">
          <img src={userImage} alt="Adopt" />
          Adopt
        </button>
      </div>
    </section>
       )}
  {activeTab === 'dogs' && (
      <section className="category">
        <h2>Dogs</h2>
        <div className="category-actions">
          <button className="category-button">Find a Mate</button>
          <button className="category-button">Find a Pup</button>
          <button className="category-button">Adopt</button>
        </div>
      </section>
      )}

      <nav className="navigation">
        <button className="nav-button">Home</button>
        <button className="nav-button">Training</button>
        <button className="nav-button">Community</button>
      </nav>
    </div>
  );


  
}


export default Front;
