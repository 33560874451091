import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function GoogleAuthRedirect() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Function to parse the query string
        const parseQueryString = (queryString) => {
            const params = new URLSearchParams(queryString);
            return Array.from(params.keys()).reduce((acc, key) => ({
                ...acc,
                [key]: params.get(key)
            }), {});
        };

        // Parse the query string
        const queryParams = parseQueryString(location.search);

        // Check if the token exists
        if (queryParams.token) {
            localStorage.setItem('token', queryParams.token);
            // Redirect to the home page or dashboard
            navigate('/');
        } else {
            // No token found, redirect to login
            navigate('/login');
        }
    }, [location, navigate]);

    // Render nothing or a loading spinner
    return null;
}

export default GoogleAuthRedirect;
