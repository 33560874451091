import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DatePicker, message, TimePicker,Table } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../redux/features/alertSlice";
import AppointmentSearch from "./AppointmentSearch";
import "../styles/BookingPage.css";

const BookingPage = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const [doctors, setDoctors] = useState([]);
  const [appointments, setAppointment] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState();
  const [isAvailable, setIsAvailable] = useState(false);
  const dispatch = useDispatch();

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [bookingDate, setBookingDate] = useState("");
  const [timings, setTimings] = useState([]);
  const [doctorId, setDoctorId] = useState('');

  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  
  
  

  const isTimeSlotInPast = (selectedDate, timeSlotStartTime) => {
    const now = new Date();
    now.setSeconds(0, 0); // Normalize to the start of the current minute

    const timeSlotDate = new Date(selectedDate);
    const [hours, minutes] = timeSlotStartTime.split(':').map(Number);
    timeSlotDate.setHours(hours, minutes, 0, 0); // Set time slot hours and minutes

    console.log(`Now: ${now.toString()}`);
    console.log(`Selected Date: ${selectedDate}`);
    console.log(`Time Slot Date: ${timeSlotDate.toString()}`);
    console.log(`Is time slot in the past: ${timeSlotDate < now}`);

    return timeSlotDate < now && now.toDateString() === timeSlotDate.toDateString();
  };

  const isTimeSlotInPastOrTooSoon = (selectedDate, timeSlotStartTime) => {
    const now = new Date();
    const selectedDateObj = new Date(selectedDate);
    selectedDateObj.setHours(0, 0, 0, 0); // Reset hours for the selected date
  
    // Parse the time slot start time and set it on the selected date
    const [hours, minutes] = timeSlotStartTime.split(':').map(Number);
    const timeSlotDateObj = new Date(selectedDate);
    timeSlotDateObj.setHours(hours, minutes, 0, 0);
  
    // Check if the time slot is in the past
    if (timeSlotDateObj < now) {
      return true;
    }
  
    // Check if the selected date is today and the time slot is within the next hour
    if (selectedDateObj.getTime() === now.setHours(0, 0, 0, 0)) {
      const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
      return timeSlotDateObj < oneHourLater;
    }
  
    return false;
  };
  
  // login user data
  const getUserDatass = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorById",
        { doctorId: params.doctorId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const fetchedDoctorId = res.data.data._id.$oid; // Assuming _id is a nested property
        setDoctors(res.data.data);
        setDoctorId(fetchedDoctorId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorById",
        { doctorId: params.doctorId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const fetchedDoctorId = res.data.data._id; // Assuming _id is a nested property
        console.log("Fetched Doctor ID:", fetchedDoctorId);
        setDoctors(res.data.data);
        setDoctorId(fetchedDoctorId);
      }
    } catch (error) {
      console.error("Error fetching doctor data:", error);
    }
  };

  

  const getAppointments = async () => {
    try {
      const res = await axios.get("/api/v1/user/user-appointmentsss", 
     
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) {
        setAppointment(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleDateChange = (date) => {
    // Reset the time part to the start of the day for consistency
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);
    console.log(`DatePicker selected date: ${selectedDate.toISOString()}`);
    setBookingDate(selectedDate); // Store the date in state
  };


  const fetchAvailableTimeSlots = async (selectedDate) => {
    // Implement the logic to fetch time slots for the given date
    // For example, you might need to make an API call here
    try {
      const response = await axios.get(`/api/v1/doctor/available-time-slots?doctorId=${doctorId}&date=${selectedDate}`);
      if (response.data.success) {
        setAvailableTimeSlots(response.data.timeSlots);
      } else {
        setAvailableTimeSlots([]); // Reset if no time slots are available for the selected date
      }
    } catch (error) {
      console.error("Error fetching available time slots:", error);
      setAvailableTimeSlots([]);
    }
  };
  

  const handleBooking = async () => {
    try {
      if (!selectedTimeSlot || !bookingDate) {
        return message.error(" Time Slot, and Booking Date are required");
      }
      dispatch(showLoading());
      const res = await axios.post(
        "/api/v1/user/book-appointment",
        {
          doctorId: params.doctorId,
          userId: user._id,
          doctorInfo: doctors,
          userInfo: user,
          bookingDate,  // Include the new parameter
          timings: selectedTimeSlot,
          doctorPhone: doctors.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (res.data.success) {
       
        message.success(res.data.message);

      

}
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
    }
  };
  const handleRefresh = () => {
    window.location.reload();
  };

  const updateAvailableTimeSlots = (bookedTimeSlots) => {
    // Filter out the booked time slots
    const filteredTimeSlots = availableTimeSlots.filter(
      (slot) => !bookedTimeSlots.includes(slot.startTime)
    );

    // Update the state with the filtered time slots
    setAvailableTimeSlots(filteredTimeSlots);
  };

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "bookingDate",
      render: (text, record) => (
        <span>
          {moment(record.date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Time",
      dataIndex: "timings",
      render: (timings) => (
        <span>
          {timings
            ? `${moment(timings.startTime, "HH:mm").format("HH:mm")} - ${moment(
                timings.endTime,
                "HH:mm"
              ).format("HH:mm")}`
            : "-"}
        </span>
      ),
    },
  
  ];


  useEffect(() => {
    getUserData();  
    //eslint-disable-next-line
  }, []);


  //testing 

  useEffect(() => {
    const fetchTimeSlots = async () => {
      // Fetch available time slots from the doctor's data
      try {
        const res = await axios.post(
          "/api/v1/doctor/getDoctorById",
          { doctorId: params.doctorId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          const fetchedTimeSlots = res.data.data.timeSlots || [];
          setAvailableTimeSlots(fetchedTimeSlots);
        }
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    };

    fetchTimeSlots();
  }, [params.doctorId]);


  useEffect(() => {
    console.log("Doctor ID:", doctorId);
  }, [doctorId]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const res = await getAppointments(params.doctorId,params.timings);
        
        if (res.data.success) {
          setAppointment(res.data.data);
          const timings = res.data.data.map(appointment => appointment.timings);
          setTimings(timings);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAppointments();
  }, [params.doctorId,params.timings]);

  const filteredTimeSlots = availableTimeSlots.filter(
    (slot) => !timings.includes(slot.startTime)
  );


   useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const res = await getAppointments(params.doctorId, params.timings);

        if (res.data.success) {
          setAppointment(res.data.data);
          const bookedTimeSlots = res.data.data.map((appointment) => appointment.timings.startTime);
          setTimings(bookedTimeSlots);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAppointments();
  }, [params.doctorId, params.timings]);
  

  return (
    <Layout>
    <div className="container">
      <div className="booking-header">
      <h3>Booking Page</h3>
      </div>
   
        {doctors && (
          <div className="doctor-info">
            <h4>
              Dr.{doctors.firstName} {doctors.lastName}
            </h4>
            <p>Fees : {doctors.feesPerCunsaltation}</p>
            <p>phone : {doctors.phone}</p>
            
            <div>
      {/* Your other components */}
      {doctorId && (
                <AppointmentSearch
                  doctorId={doctorId}
                  getUserData={getUserData}
                  updateAvailableTimeSlots={updateAvailableTimeSlots}
                />
              )}
    </div>
    <button  className="btn" onClick={handleRefresh}>Refresh Page</button>
            
            {doctors.timeSlots ? (
              <div className="time-slots-container">
                <h4>Available Time Slots:</h4>
                <ul className="time-slots">
                {availableTimeSlots.map((timeSlot, index) => {
                  const isInPastOrTooSoon = isTimeSlotInPastOrTooSoon(bookingDate, timeSlot.startTime);
    const isSelected = selectedTimeSlot === timeSlot;
    const timeSlotClass = isInPastOrTooSoon
      ? 'time-slot past-time-slot'
      : isSelected
      ? 'time-slot selected-time-slot'
      : 'time-slot';

      return (
        <li key={index} className={timeSlotClass} onClick={() => {
           if (!isInPastOrTooSoon) {
            setSelectedTimeSlot(timeSlot);
          }
        }}
        // Optionally disable the click if the time slot is in the past or too soon
        disabled={isInPastOrTooSoon}
      >
                      {timeSlot.startTime} - {timeSlot.endTime}
                    </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <p>No time slots available</p>
            )}
           
            <div className="booking-actions">
            <DatePicker selected={bookingDate} onChange={(date) => setBookingDate(date)} />          




              <button  className="btn" onClick={handleBooking}>
                Book Now
              </button>
           
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BookingPage;