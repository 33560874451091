import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { PhoneOutlined } from '@ant-design/icons';
import DatePicker from 'react-datepicker';
import { AppBar, Toolbar, InputBase, Paper, Fab, CardContent, Typography, Badge, IconButton, Box, CssBaseline, Drawer, Grid,CardActions,useTheme, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Mail as MailIcon, Menu as MenuIcon, CalendarToday as CalendarIcon, TableView as TableIcon, PersonAdd as AddPatientIcon  } from '@mui/icons-material';
import yourAdImageUrl from '../../images/COVER.png';
import "./../../styles/vaccine.css";
import { showLoading, hideLoading } from "../../redux/features/alertSlice";
import Layout from "./../../components/Layout";
import { MinusCircleOutlined,CopyOutlined, PlusOutlined } from '@ant-design/icons';
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import yourImageUrl from '../../images/allgr.png';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { message, Form, Input, Row, Col,Modal,Table, Button, Space, Select, InputNumber,Divider,Card, Descriptions, Spin ,Tabs,} from 'antd';
import { Calendar } from '@fullcalendar/react';
import Sidebar from '../Sidebar';
const drawerWidth = 240;

const { TabPane } = Tabs;
const VaccineReview = () => {
  const [, forceUpdate] = useState();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [initialCalendarDate, setInitialCalendarDate] = useState(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const modernSoothingGreen = '#029d49';


  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const initialLoad = useRef(true);
  
  const { user } = useSelector((state) => state.user);
  const [vaccineData, setVaccineData] = useState([]);
  
  const [bookingDate, setBookingDate] = useState("");
  const [vaccineDate, setVaccineDate] = useState("");
  const [vaccineDue, setDueDate] = useState("");
  const [initialBirthDate, setInitialBirthDate] = useState(null);

  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const params = useParams();
  const [clinicemail, setClinicemail] = useState('');
  const dispatch = useDispatch();
  const [patientId, setPatientId] = useState('');
  const [name, setName] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { TextArea } = Input;

  const [vaccineDates, setVaccineDates] = useState([]);

  const [vaccinationData, setVaccinationData] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [filteredData, setFilteredData] = useState([]);

  const [tableData, setTableData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [eventsOfTheDay, setEventsOfTheDay] = useState([]);
  const [calendarKey, setCalendarKey] = useState(0);


  useEffect(() => {
    // Check if doctor data is available
    if (doctor && doctor.clinicemail) {
      const startOfMonth = moment().startOf('month').toISOString();
      const endOfMonth = moment().endOf('month').toISOString();
      fetchVaccinationData('custom', startOfMonth, endOfMonth);
    }
  }, [doctor]); 

  useEffect(() => {
    setInitialCalendarDate(moment().startOf('month').toISOString());
  }, []);

  const showEventModal = (info) => {
    const clickedDate = info.event.start;
  
    const eventsOnSameDay = calendarEvents.filter(event => {
      return moment(event.start).isSame(clickedDate, 'day');
    });
  
    setEventsOfTheDay(eventsOnSameDay);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEventsOfTheDay([]); // Clear the events of the day
  };




const dogBreeds = [
  "Labrador Retriever",
  "German Shepherd",
  "Golden Retriever",
  "French Bulldog",
  "Bulldog",
  "Poodle",
  "Beagle",
  "Rottweiler",
  "German Shorthaired Pointer",
  "Yorkshire Terrier",
  "Boxer",
  "Siberian Husky",
  "Dachshund",
  "Great Dane",
  "Doberman Pinscher",
  "Australian Shepherd",
  "Cavalier King Charles Spaniel",
  "Shih Tzu",
  "Boston Terrier",
  "Pug",
  "Akita",
  "Alaskan Malamute",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Staffordshire Terrier",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Basenji",
  "Basset Hound",
  "Bernese Mountain Dog",
  "Bloodhound",
  "Border Collie",
  "Borzoi",
  "Brittany",
  "Bull Terrier",
  "Cairn Terrier",
  "Cane Corso",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Collie",
  "Dalmatian",
  "English Cocker Spaniel",
  "English Setter",
  "English Springer Spaniel",
  "Flat-Coated Retriever",
  "Fox Terrier",
  "Giant Schnauzer",
  "Greyhound",
  "Irish Setter",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "Komondor",
  "Kuvasz",
  "Lhasa Apso",
  "Maltese",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Neapolitan Mastiff",
  "Newfoundland",
  "Norwegian Elkhound",
  "Old English Sheepdog",
  "Papillon",
  "Pekingese",
  "Pit Bull",
  "Pointer",
  "Rhodesian Ridgeback",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Scottish Terrier",
  "Shar Pei",
  "Shetland Sheepdog",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Tibetan Mastiff",
  "Vizsla",
  "Weimaraner",
  "Welsh Corgi",
  "West Highland White Terrier",
  "Whippet",
  "Wirehaired Pointing Griffon",
  "Indian Pariah Dog",
  "Mudhol Hound",
  "Rajapalayam",
  "Kanni",
  "Chippiparai",
  "Gaddi Kutta",
  "Kumaon Mastiff",
  "Bakharwal Dog",
  "Rampur Greyhound",
];

const catBreeds = [
  // ...list of cat breeds...
  "Abyssinian",
  "Bengal",
  "Abyssinian",
  "Bengal",
  "Persian",
  "Maine Coon",
"Persian",
"Siamese",
"Ragdoll",
"Abyssinian",
"Sphynx",
"American Shorthair",
"British Shorthair",
"Russian Blue",
"Oriental Shorthair",
"Himalayan",
  // ...other cat breeds...
];


  const handleOpenInMaps = (location) => {
    console.log("Original location:", location); // Check the original location format

  
    const matches = location.match(/(-?\d+\.\d+)/g);
    if (matches && matches.length === 2) {
      const [latitude, longitude] = matches;
      console.log("Latitude:", latitude, "Longitude:", longitude); 
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  } else {
    message.error("Invalid location format");
  }
};
  const convertToCalendarEvents = (vaccinationData) => {
    return vaccinationData.map((patient) => {
      return patient.vaccinationHistory.map((vaccine) => {
        const eventColor = getEventColor(vaccine.vaccineName);// Logging the vaccine name here
  
        return {
          title: `${patient.name} - ${vaccine.vaccineName}`,
          start: moment(vaccine.nextDueDate).toISOString(),
          allDay: true,
          color: eventColor, // Set the color here
        extendedProps: {
          vaccineName: vaccine.vaccineName
        },
          phoneNumber: patient.phoneNumber,
          location: patient.location
        };
      });
    }).flat();
  };
  
  const getEventColor = (vaccineName) => {
    if (vaccineName === 'Multi Component Vaccine') {
      return 'blue'; // Replace 'blue' with your desired color for multi component vaccines
    } else if (vaccineName === 'Anti Rabies Vaccine') {
      return 'red'; // Replace 'red' with your desired color for anti rabies vaccines
    } else {
      return 'green'; // Default color for other vaccines
    }
  };
   
    useEffect(() => {
      const fetchVaccinationData = async () => {
        // Logic to fetch data from backend
        try {
          const response = await axios.get('/api/path-to-your-data');
          if (response.data.success) {
            const formattedEvents = convertToCalendarEvents(response.data.data);
            setCalendarEvents(formattedEvents); // Set the state with formatted events
          } else {
            // Handle the failure case
            console.error('Failed to fetch data');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchVaccinationData();
    }, []); // Empty dependency array means this effect runs once on component mount
    
  
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Vaccine Name',
      dataIndex: 'vaccineName',
      key: 'vaccineName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (phoneNumber) => (
        <>
          <a href={`tel:${phoneNumber}`} style={{ marginRight: 8 }}>
            +{phoneNumber}
          </a>
          <Button 
            icon={<PhoneOutlined />} 
            onClick={() => window.open(`tel:${phoneNumber}`)}
            style={{ marginRight: 8 }}
          />
          <Button 
            icon={<CopyOutlined />} 
            onClick={() => navigator.clipboard.writeText(phoneNumber)}
          />
        </>
      ),
    
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Next Due Date',
      dataIndex: 'start',
      key: 'start',
      render: (text) => <span>{moment(text).format('YYYY-MM-DD')}</span>,
    },
    // ... add more columns as needed
  ];

  const prepareTableData = (vaccinationData) => {
    // First, flatten the array
    const flattenedData = vaccinationData.flatMap((patient) => 
      patient.vaccinationHistory.map((vaccine) => ({
        key: `${patient.patientId}-${vaccine._id}`, // Unique key for each row
        name: patient.name,
        vaccineName: vaccine.vaccineName,
        phoneNumber: patient.phoneNumber,
        location: patient.location,
        start: moment(vaccine.nextDueDate).format('YYYY-MM-DD'), // Formatted date
      }))
    );
  
    // Now, sort the array based on the nextDueDate
    const sortedData = flattenedData.sort((a, b) => {
      return moment(a.start) - moment(b.start);
    });
  
    return sortedData;
  };
  


  // Function to handle month selection
  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    filterDataByMonth(date);
  };


 

  
  // Function to filter data based on selected month
  const filterDataByMonth = (date) => {
    const startOfMonth = date.startOf('month').toDate();
    const endOfMonth = date.endOf('month').toDate();
    const filtered = vaccinationData
      .map((patient) => ({
        ...patient,
        vaccinationHistory: patient.vaccinationHistory.filter((vaccine) => {
          const dueDate = moment(vaccine.nextDueDate).toDate();
          return dueDate >= startOfMonth && dueDate <= endOfMonth;
        }),
      }))
      .filter((patient) => patient.vaccinationHistory.length > 0);

    setFilteredData(filtered);
  };

  const handleVaccineDateChange = (index, dateValue, isDueDate = false) => {
    const newVaccineDates = [...vaccineDates];
    if (!newVaccineDates[index]) {
      newVaccineDates[index] = { vaccineDate: null, vaccineDue: null };
    }
    if (isDueDate) {
      newVaccineDates[index].vaccineDue = dateValue;
    } else {
      newVaccineDates[index].vaccineDate = dateValue;
    }
    setVaccineDates(newVaccineDates);
  };
  const copyToClipboard = (text) => {
    if (navigator.clipboard && text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          message.success('Address copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    } else {
      // Clipboard API not available
      message.error('Clipboard not available');
    }
  };

  const getTodayRange = () => {
    return {
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString()
    };
  };
  
  const getTomorrowRange = () => {
    return {
      startDate: moment().add(1, 'days').startOf('day').toISOString(),
      endDate: moment().add(1, 'days').endOf('day').toISOString()
    };
  };
 

  const getNextWeekRange = () => {
    return {
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().add(7, 'days').endOf('day').toISOString()
    };
  };

  const hideEmptyWeekRows = () => {
    document.querySelectorAll('.fc-daygrid-body .fc-row').forEach(row => {
      const otherMonthDays = row.querySelectorAll('.fc-daygrid-day.fc-other-month');
      if (otherMonthDays.length === row.querySelectorAll('.fc-daygrid-day').length) {
        row.classList.add('fc-row-hidden'); // Add class to hide row
        row.classList.remove('fc-row-visible');
      } else {
        row.classList.remove('fc-row-hidden');
        row.classList.add('fc-row-visible');
      }
    });
  };



  const handleDatesSet = (info) => {
    const startDate = info.startStr;
    const endDate = info.endStr;
    fetchVaccinationData('custom', startDate, endDate);
    hideEmptyWeekRows();
  };




  const fetchVaccinationData =  async (rangeType, startDate = '', endDate = '') => {
    let range;
    if (rangeType === 'custom') {
      range = { startDate, endDate };
    }
  
    try {
      const response = await axios.get('/api/v1/user/vaccinations-due', {
        params: {
          startDate: range.startDate,
          endDate: range.endDate,
          clinicemail: doctor?.clinicemail
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
          setVaccinationData(response.data.data);
          const events = convertToCalendarEvents(response.data.data);
          setCalendarEvents(convertToCalendarEvents(response.data.data));
          setCalendarKey(prevKey => prevKey + 1);
          setCalendarEvents(events);
      } else {
          console.error('Failed to fetch data');
          setVaccinationData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setVaccinationData(null);
    }
  };
  





  useEffect(() => {
    // When your vaccination data is fetched, convert it to calendar events
    if (Array.isArray(vaccinationData)) { // Ensure that vaccinationData is an array
      const preparedData = prepareTableData(vaccinationData);
      setTableData(preparedData); 
      const events = vaccinationData.map((patient) => {
        return patient.vaccinationHistory.map((vaccine) => {
          return {
            title: `${patient.name} - ${vaccine.vaccineName}`,
            start: moment(vaccine.nextDueDate).toISOString(),
            allDay: true,
            phoneNumber: patient.phoneNumber, // Include phoneNumber
          location: patient.location 
          };
        });
      }).flat(); // Use .flat() to flatten the array of arrays
      filterDataByMonth(moment());
      setCalendarEvents(events);
    }
  }, [vaccinationData]); 


 





  const handleSearch = async () => {
    console.log("Search Term:", searchTerm);
    if (!doctor?.clinicemail) {
      message.error("Doctor information is missing.");
      return;
    }
  
    try {
      
      const response = await axios.get(`/api/v1/user/patient`, {
        params: { searchTerm: searchTerm, clinicemail: doctor.clinicemail,},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log('Received data:', response.data); // Temporary log for debugging

      // Check if data is received
      if (response.data && response.data.success) {
        const patients = response.data.data;
       
        // Assuming the search might return multiple patients
        if (patients.length > 0) {
          const patientWithFormattedDate = {
            ...patients[0],
            birthDate: patients[0].birthDate ? moment(patients[0].birthDate).format('YYYY-MM-DD') : null, // Convert other date fields similarly
          };
          
          setPatientData(patients[0]); // Here, taking the first patient as an example
          form.setFieldsValue(patients[0]);
        } else {
          message.info('No patient found with the provided ID or phone number.');
          setPatientData(null);
        }
      } else {
        message.error('Failed to search for patient.');
        setPatientData(null);
      }
    } catch (error) {
      console.error('Error during patient search:', error);
      message.error('Failed to search for patient.');
      setPatientData(null);
    }
  };
  




  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
  
       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    
    //eslint-disable-next-line
  }, []);
 
 
  const handleUpdatePatient = async (values) => {
    console.log("Form submit values:", values);
    console.log("update Term:", searchTerm);
    if (!patientData || !doctor?.clinicemail) {
      message.error("Required data is missing.");
      return;
    }
  
    try {
      const response = await axios.put(`/api/v1/user/updatePatient/${patientData.patientId}/${doctor.clinicemail}`, values,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
        message.success('Patient updated successfully.');
        // Refresh or redirect logic here
      } else {
        message.error('Failed to update patient: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error during patient update:', error);
      message.error('Failed to update patient.');
    }
  };



  const fetchPatientData = async () => {
    try {
      const res = await axios.get(
        "/api/v1/user/patientss", // Update this endpoint to the correct one
           // Assuming you want to record which user added the patient
        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setPatient(res.data.data);

       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    fetchPatientData();
    //eslint-disable-next-line
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{
 
        flexGrow: 1,
        '::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%', // Adjust the height as needed
          backgroundColor: '#f0f2f5',
          zIndex: -1
        },
        position: 'relative', // Ensure that the pseudo-element is positioned relative to this Box
      }}>
  <AppBar position="fixed" elevation={0} sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent', backdropFilter: 'blur(0px)' }}>
  <Toolbar sx={{ 
          display: 'flex', 
          justifyContent: 'space-between' // This will push the menu icon to the left and search bar to the right
        }}>
               <IconButton color="inherit"  onClick={handleDrawerToggle} sx={{ mr: 'auto' }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h4" sx={{ 
    mb: 4, 
fontWeight:'900',
    background: 'white',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1rem',
  }}>
{user?.name}
  </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{
           flexGrow: 1,
        p: isXsDown ? 2 : 3, // Responsive padding, smaller on extra-small screens
        pb: 0,
          position: 'absolute',
          top: 0,
   
          left: 0,
          width: '100%',
          height: '50vh', // Background image height
          backgroundImage: `url(${yourImageUrl})`, // Your background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: -1, // Behind the content
        }} />
{isMdUp ? (
  <Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
) : (
  <Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
)}   <Toolbar /> {/* This Toolbar is necessary to offset the content below the AppBar */}
    <div className="container">

    <Modal
  title="Event Details"
  visible={isModalVisible}
  onCancel={handleCancel}
  footer={null} // No footer buttons
>
  <div>
    {eventsOfTheDay.map((event, index) => (
      <div key={index}>
        <p><strong>Name:</strong> {event.title.split(" - ")[0]}</p>
        <p>
          <strong>Phone Number:</strong> 
          {event.phoneNumber ? (
            <>
          <a href={`tel:${event.phoneNumber}`} style={{ marginLeft: 5 }}>
       +{event.phoneNumber}
      </a>
      <a href={`tel:${event.phoneNumber}`} style={{ border: 'none', background: 'none', padding: 0, boxShadow: 'none' }}>
        <Button type="link" icon={<PhoneOutlined />}>Call</Button>
      </a>
            </>
          ) : 'N/A'}
        </p>
        <p>
          <strong>Location:</strong> {event.location || 'N/A'}
          {event.location && (
            <>
             <Button 
  type="link"
  onClick={() => handleOpenInMaps(event.location)}
>
  Open in Maps
</Button>

              <Button 
                type="link" 
                onClick={() => copyToClipboard(event.location)}
              >
                Copy
              </Button>
            </>
          )}
        </p>
        <p><strong>Vaccine:</strong> {event.title.split(" - ")[1]}</p>
        <Divider />
      </div>
    ))}
  </div>
</Modal>

      
      <div className="patient-form-container">
      <Tabs defaultActiveKey="1" type="card" className="custom-tabs">
          <TabPane tab="Calendar" key="1">
      <div className="calendar-container">
      <FullCalendar

  plugins={[dayGridPlugin]}
  initialView="dayGridMonth"
  initialDate={initialCalendarDate}
  events={calendarEvents}
  datesSet={handleDatesSet}
  weekNumbers={false}
  contentHeight="auto"
  
  eventClick={showEventModal}
  dayMaxEventRows={3} // Set the maximum number of events per day
  moreLinkContent={(arg) => {
    return `+${arg.num} more`;
  }}
/>


      </div>
      </TabPane>
      <TabPane tab="Table" key="2">
            {/* Your table component or structure goes here */}
            <div className="table-view-container">
              {/* Replace with your table component */}
              <div >
              <Table columns={columns} dataSource={tableData} />
      </div>
            </div>
          </TabPane>
      </Tabs>



   </div>
 
    </div>
    </Box>
    </Box>
  );
};

export default VaccineReview;