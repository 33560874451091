import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout";
import axios from "axios";
import { message, Table } from "antd";
import '../../styles/doctor.css';

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);

  // Get doctors and sort them by createdAt in descending order
  const getDoctors = async () => {
    try {
      const res = await axios.get("/api/v1/admin/getAllDoctors", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) {
        // Assuming res.data.data is an array of doctor objects
        const sortedDoctors = res.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt); // Descending order
        });
        setDoctors(sortedDoctors);
      }
    } catch (error) {
      console.log(error);
      message.error("Could not fetch doctors.");
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  const handleAccountStatus = async (record, status) => {
    try {
      const res = await axios.post(
        "/api/v1/admin/changeAccountStatus",
        { doctorId: record._id, userId: record.userId, status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getDoctors(); // Refresh the list instead of reloading the page
      }
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "clinicemail",
    },
    {
      title: "Phone",
      dataIndex: "clinicNumber",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="d-flex">
          {record.status === "pending" ? (
            <button
              className="btn btn-success"
              onClick={() => handleAccountStatus(record, "approved")}
            >
              Approve
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => handleAccountStatus(record, "rejected")}>
              Reject
            </button>
          )}
        </div>
      ),
    },
  ];
  const getRowClassName = (record, index) => {
    return record.status === "approved" ? "approved-doctor" : "pending-doctor";
  };
  return (
    <Layout>
      <h1 className="text-center m-3">All Doctors</h1>
      <Table columns={columns} dataSource={doctors} rowKey="_id"   rowClassName={getRowClassName}  />
    </Layout>
  );
};

export default Doctors;
