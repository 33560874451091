import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

import "./../../styles/AddPatient.css";
import { showLoading, hideLoading } from "../../redux/features/alertSlice";
import Layout from "./../../components/Layout";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { useParams, useNavigate } from "react-router-dom";
import { message, Form,Collapse, Input, Row, Col, Button, Space, Select, InputNumber,Divider,Card, Descriptions, Spin, } from 'antd';

import Sidebar from '../Sidebar';
import yourImageUrl from '../../images/allgr.png';
import { AppBar, Toolbar, InputBase, Paper, Fab, CardContent, Typography, Badge, IconButton, Box, CssBaseline, Drawer, Grid,CardActions,useTheme, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Mail as MailIcon, Menu as MenuIcon, CalendarToday as CalendarIcon, TableView as TableIcon, PersonAdd as AddPatientIcon  } from '@mui/icons-material';
const drawerWidth = 240;
const SearchEditPatient = () => {
 
  const { user } = useSelector((state) => state.user);
  const { Panel } = Collapse;
  const [bookingDate, setBookingDate] = useState("");
  const [vaccineDate, setVaccineDate] = useState("");
  const [vaccineDue, setDueDate] = useState("");
  const [initialBirthDate, setInitialBirthDate] = useState(null);

  const [isFetchingLocation, setIsFetchingLocation] = useState(false);


  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const params = useParams();
  const [clinicemail, setClinicemail] = useState('');
  const dispatch = useDispatch();
  const [patientId, setPatientId] = useState('');
  const [name, setName] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { TextArea } = Input;
  const [location, setLocation] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [species, setSpecies] = useState('');

 




  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const modernSoothingGreen = '#029d49';


  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const handlePanelClick = patient => {
    setPatientData(patient);
    form.setFieldsValue(patient);
  };

  const handleSearch = async () => {
    console.log("Search Term:", searchTerm);
    if (!doctor?.clinicemail) {
      message.error("Doctor information is missing.");
      return;
    }
  
    try {
      
      const response = await axios.get(`/api/v1/user/patient`, {
        params: { searchTerm: searchTerm, clinicemail: doctor.clinicemail,},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log('Received data:', response.data); // Temporary log for debugging

      // Check if data is received
      if (response.data && response.data.success) {
        const patients = response.data.data;
        setResults(response.data.data);
       
        // Assuming the search might return multiple patients
        if (patients.length > 0) {
          const formattedPatientData = {
            ...patients[0],
            birthDate: patients[0].birthDate ? moment(patients[0].birthDate) : null,
          };
    
          setPatientData(formattedPatientData);
          form.setFieldsValue(formattedPatientData);
          setPatientData(patients[0]); // Here, taking the first patient as an example
          form.setFieldsValue(patients[0]);
        } else {
          message.info('No patient found with the provided  phone number.');
          setPatientData(null);
        }
      } else {
        message.error('Failed to search for patient.');
        setPatientData(null);
      }
    } catch (error) {
      console.error('Error during patient search:', error);
      message.error('Failed to search for patient.');
      setPatientData(null);
    }
  };
  
  const handleSpeciesChange = (value) => {
    setSpecies(value);
  };
  

const dogBreeds = [
  "Labrador Retriever",
  "German Shepherd",
  "Golden Retriever",
  "French Bulldog",
  "Bulldog",
  "Poodle",
  "Beagle",
  "Rottweiler",
  "German Shorthaired Pointer",
  "Yorkshire Terrier",
  "Boxer",
  "Siberian Husky",
  "Dachshund",
  "Great Dane",
  "Doberman Pinscher",
  "Australian Shepherd",
  "Cavalier King Charles Spaniel",
  "Shih Tzu",
  "Boston Terrier",
  "Pug",
  "Akita",
  "Alaskan Malamute",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Staffordshire Terrier",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Basenji",
  "Basset Hound",
  "Bernese Mountain Dog",
  "Bloodhound",
  "Border Collie",
  "Borzoi",
  "Brittany",
  "Bull Terrier",
  "Cairn Terrier",
  "Cane Corso",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Collie",
  "Dalmatian",
  "English Cocker Spaniel",
  "English Setter",
  "English Springer Spaniel",
  "Flat-Coated Retriever",
  "Fox Terrier",
  "Giant Schnauzer",
  "Greyhound",
  "Irish Setter",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "Komondor",
  "Kuvasz",
  "Lhasa Apso",
  "Maltese",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Neapolitan Mastiff",
  "Newfoundland",
  "Norwegian Elkhound",
  "Old English Sheepdog",
  "Papillon",
  "Pekingese",
  "Pit Bull",
  "Pointer",
  "Rhodesian Ridgeback",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Scottish Terrier",
  "Shar Pei",
  "Shetland Sheepdog",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Tibetan Mastiff",
  "Vizsla",
  "Weimaraner",
  "Welsh Corgi",
  "West Highland White Terrier",
  "Whippet",
  "Wirehaired Pointing Griffon",
  "Indian Pariah Dog",
  "Mudhol Hound",
  "Rajapalayam",
  "Kanni",
  "Chippiparai",
  "Gaddi Kutta",
  "Kumaon Mastiff",
  "Bakharwal Dog",
  "Rampur Greyhound",
  "Indian Spitz",
  "Pomeranian",
  "Non Descript",
];

const catBreeds = [
  // ...list of cat breeds...
  "Abyssinian",
  "Bengal",
  "Maine Coon",
"Persian",
"Siamese",
"Ragdoll",
"Abyssinian",
"Sphynx",
"American Shorthair",
"British Shorthair",
"Russian Blue",
"Oriental Shorthair",
"Himalayan",

];
const handleBirthDateChange = (date) => {
  const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
  form.setFieldsValue({ birthDate: formattedDate });
};


const handleGetCurrentLocation = () => {
  if (navigator.geolocation) {
    setIsFetchingLocation(true); // Start loading
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      setLocation(googleMapsUrl);
      form.setFieldsValue({ location: googleMapsUrl });
      setIsFetchingLocation(false); // Stop loading
    }, () => {
      message.error('Unable to retrieve your location');
      setIsFetchingLocation(false); // Stop loading
    });
  } else {
    message.error('Geolocation is not supported by this browser.');
    setIsFetchingLocation(false); // Stop loading
  }
};





  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
  
       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    
    //eslint-disable-next-line
  }, []);
 
 
  const handleUpdatePatient = async (values) => {

    console.log("update Term:", searchTerm);
    if (!patientData || !doctor?.clinicemail) {
      message.error("Required data is missing.");
      return;
    }
   
  
    try {
      const response = await axios.put(`/api/v1/user/updatePatient/${patientData.patientId}/${doctor.clinicemail}`, values,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
        message.success('Patient updated successfully.');

        console.log('Premium:', doctor?.premium);

        if (doctor && doctor.premium) {
          sendWhatsAppMessagesBasedOnVaccinationRecords(values,);
        }

        // Refresh or redirect logic here
      } else {
        message.error('Failed to update patient: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error during patient update:', error);
      message.error('Failed to update patient.');
    }
  };

  const determineTemplateName = (species, vaccineName) => {
    if (species === 'dog' && vaccineName === 'Multi Component Vaccine') {
      return 'dogmcv';
    } else if (species === 'dog' && vaccineName === 'Anti Rabies Vaccine') {
      return 'donerabies';
    } else if (species === 'cat' && vaccineName === 'Multi Component Vaccine') {
      return 'catmcv';
    } else if (species === 'cat' && vaccineName === 'Anti Rabies Vaccine') {
      return 'donerabies';
    }
    return ''; // Default case, though you should handle this appropriately
  };


 const sendWhatsAppMessagesBasedOnVaccinationRecords = (values, patientId, doctorClinicEmail) => {
  // Ensure vaccinationHistory is an array before proceeding
  if (!values.vaccinationHistory || !Array.isArray(values.vaccinationHistory)) {
    console.error('vaccinationHistory is undefined or not an array');
    return;
  }

  const today = moment().startOf('day');
  values.vaccinationHistory.forEach(record => {
    const vaccinationDate = moment(record.dateOfVaccination);
    if (vaccinationDate.isSame(today, 'day')) {
      const templateName = determineTemplateName(values.species, record.vaccineName);
      if (!templateName) {
        console.log('No template name determined for record:', record);
        return;
      }
      const templateVariables = [
        values.name,
        vaccinationDate.format('DD-MM-YYYY'),
        patientData.firstName,
        patientData.clinicNumber,
      ];
      updateMessageCount(clinicemail);
      sendWhatsAppMessage(values.phoneNumber, templateName, templateVariables, patientId, doctorClinicEmail);
    }
  });
};

const updateMessageCount = async (clinicemail) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1; // getMonth() returns month from 0-11
  const successfulNumber = "exampleNumber"; // Placeholder, use actual successful number

  console.log('Sending data to backend:', { clinicemail, year, month, successfulNumber });

  try {
    const response = await axios.post('/api/v1/user/counter', {
      clinicemail: clinicemail,
      year: year,
      month: month,
      successfulNumber: successfulNumber
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    console.log('Response from backend:', response.data);
    console.log('Message count updated successfully.');
  } catch (error) {
    console.error('Failed to update message count:', error);
  }
};

  const sendWhatsAppMessage = async (phoneNumber, templateName, templateVariables, patientId, clinicEmail) => {
    const requestData = {
      phoneNumber,
      templateName,
      templateVariables,
    };
    console.log('Sending WhatsApp message with:', { phoneNumber, templateName, templateVariables });

    try {
      const response = await axios.put(`/api/v1/user/sendsms`, requestData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (response.data.success) {
        console.log('WhatsApp message sent successfully.');
      } else {
        console.error(`Failed to send WhatsApp message: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error during WhatsApp message sending:', error);
    }
  };
  
  
  
  



  

  const fetchPatientData = async () => {
    try {
      const res = await axios.get(
        "/api/v1/user/patientss", // Update this endpoint to the correct one
           // Assuming you want to record which user added the patient
        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setPatient(res.data.data);

       
        const fetchedClinicEmail = res.data.data.clinicemail;
        setClinicemail(fetchedClinicEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderPatientPanel = (patient, index) => (
    <Panel header={`${patient.name} (${patient.ownername})`} key={index}>
      {/* Here you can render detailed information about the patient */}
      <p>Species: {patient.species}</p>
      <p>Breed: {patient.breed}</p>
      {/* Add more patient details here */}
    </Panel>
  );

  useEffect(() => {
    fetchPatientData();
    //eslint-disable-next-line
  }, []);


  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Box sx={{

      flexGrow: 1,
      '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%', // Adjust the height as needed
        backgroundColor: '#f0f2f5',
        zIndex: -1
      },
      position: 'relative', // Ensure that the pseudo-element is positioned relative to this Box
    }}>
<AppBar position="fixed" elevation={0} sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent', backdropFilter: 'blur(0px)' }}>
<Toolbar sx={{ 
        display: 'flex', 
        justifyContent: 'space-between' // This will push the menu icon to the left and search bar to the right
      }}>
             <IconButton color="inherit"  onClick={handleDrawerToggle} sx={{ mr: 'auto' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" sx={{ 
    mb: 4, 
fontWeight:'900',
    background: 'white',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1rem',
  }}>
{user?.name}
  </Typography>
   
        </Toolbar>
      </AppBar>
      <Box sx={{
         flexGrow: 1,
      p: isXsDown ? 2 : 3, // Responsive padding, smaller on extra-small screens
      pb: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '30vh', // Background image heightge
        backgroundRepeat: "repeat" ,
        backgroundImage: `url(${yourImageUrl})`, // Your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1, // Behind the content
      }} />
{isMdUp ? (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
) : (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
)}   <Toolbar /> 
      
      <div className="patient-form-container" style={{ maxWidth: '600px', margin: '0 auto'}}>
        <h1>Search and Edit Patient</h1>

        {/* Search Section */}
        
        <Input
         style={{ width: '100%' }} 
  addonAfter={<SearchOutlined onClick={handleSearch} />}
  placeholder="Enter Phone Number"
  className="ant-input" 
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
  onPressEnter={handleSearch}
  maxLength={10} // restricts input to 10 characters
  pattern="\d*"  // ensures only digits can be entered
/>

{results.length > 0 && (
      <Collapse onChange={(key) => handlePanelClick(results[key])}>
        {results.map((patient, index) => renderPatientPanel(patient, index))}
      </Collapse>
    )}
        
        {/* Edit Section */}
        {patientData && (
          <>
             <Form layout="vertical" onFinish={handleUpdatePatient} className="m-3" form={form}>
             <div  className="field-container">
        {/* Basic Patient Details */}
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the patient\'s name!' }]}>
          <Input  style={{ width: '100%' }}  placeholder="Patient's Name" />
        </Form.Item>

        <Form.Item name="ownername" label="Owner Name" rules={[{ required: true, }]}>
          <Input  style={{ width: '100%' }}  placeholder="Owner's Name"        value={ownerName}
            />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, }]}>
          <Input  style={{ width: '100%' }}  placeholder="Phone  Number"        value={ownerName}
            />
        </Form.Item>

        <Form.Item name="species" label="Species" rules={[{ required: true }]}>
          <Select placeholder="Select species" onChange={handleSpeciesChange}>
            <Option value="Dog">Dog</Option>
            <Option value="Cat">Cat</Option>
            {/* Add more species options */}
          </Select>
        </Form.Item>
           <Form.Item name="breed" label="Breed" rules={[{ required: false }]}>
  <Select
    showSearch
    placeholder="Select or type a breed"
    optionFilterProp="children"
    notFoundContent={null}
  >
    {species === 'dog' ? (
      dogBreeds.map(breed => (
        <Option key={breed} value={breed}>
          {breed}
        </Option>
      ))
    ) : (
      catBreeds.map(breed => (
        <Option key={breed} value={breed}>
          {breed}
        </Option>
      ))
    )}
    <Option value="Other">Other</Option> {/* Add an "Other" option */}
  </Select>
</Form.Item>

<Form.Item name="birthDate" label="Birth Date">
            <DatePicker 
              onChange={handleBirthDateChange}
              format="YYYY-MM-DD"
              value={form.getFieldValue('birthDate') || null}
            />
          </Form.Item>
    
          <Form.Item name="location" label="Location" rules={[{ required: false }]}>
  <Input  style={{ width: '100%' }}  placeholder="Location" />
</Form.Item>
<Button onClick={handleGetCurrentLocation} disabled={isFetchingLocation}>
  {isFetchingLocation ? <Spin /> : 'Use Current Location'}
</Button>

{location && (
  <Form.Item>
    <Button 
      type="primary" className="btn"
      onClick={() => window.open(location, '_blank')}
    >
      Open in Google Maps
    </Button>
  </Form.Item>
)}

        <Divider orientation="left">Vaccination History</Divider>
        <Form.List name="vaccinationHistory">
  {(fields, { add, remove }) => (
    <>
       
        {fields.map((field, index) => (
        <div key={field.key} className="field-container">
        <Space direction="vertical" className="space-align-container">
              <Form.Item
                {...field}
                name={[field.name, 'vaccineName']}
                fieldKey={[field.fieldKey, 'vaccineName']}
                label="Vaccine Name"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select placeholder="Select Vaccine">
            <Option value="Multi Component Vaccine">Multi Component Vaccine</Option>
            <Option value="Anti Rabies Vaccine">Anti Rabies Vaccine</Option>
            {/* Add more species options */}
          </Select>
              </Form.Item>
        
   
            <Form.Item
                {...field}
                name={[field.name, 'dateOfVaccination']}
                fieldKey={[field.fieldKey, 'dateOfVaccination']}
                label="Date of Vaccination"
              >
                       <div className="vaccine-history-input">
                <DatePicker
                   dateFormat="dd/MM/yyyy" // Format the displayed date
                   wrapperClassName="datePicker"
                  selected={form.getFieldValue(['vaccinationHistory', index, 'dateOfVaccination']) ? moment(form.getFieldValue(['vaccinationHistory', index, 'dateOfVaccination'])).toDate() : null}
                  onChange={date => form.setFieldsValue({ vaccinationHistory: form.getFieldValue('vaccinationHistory').map((v, idx) => idx === index ? {...v, dateOfVaccination: date} : v) })}
                />
              
                </div>
              </Form.Item>
         
        
            <Form.Item
                {...field}
                name={[field.name, 'nextDueDate']}
                fieldKey={[field.fieldKey, 'nextDueDate']}
                label="Next Due Date"
              >
               
                <div className="vaccine-history-input">
                <DatePicker
                   dateFormat="dd/MM/yyyy" // Format the displayed date
                   wrapperClassName="datePicker"
                  selected={form.getFieldValue(['vaccinationHistory', index, 'nextDueDate']) ? moment(form.getFieldValue(['vaccinationHistory', index, 'nextDueDate'])).toDate() : null}
                  onChange={date => form.setFieldsValue({ vaccinationHistory: form.getFieldValue('vaccinationHistory').map((v, idx) => idx === index ? {...v, nextDueDate: date} : v) })}
                />
               
                </div>
              </Form.Item>
        
          
              <MinusCircleOutlined
            className="minus-circle-icon"
            onClick={() => remove(field.name)}
            style={{ color: '#ff4d4f', marginTop: '10px' }}
          />
    </Space>
    </div>
         
        ))}

      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} size="small">
        Add Vaccination Record
      </Button>
    </>
  )}
</Form.List>
        <Button type="primary" className="btn" htmlType="submit" style={{ marginTop: '20px' }}>Update Patient</Button>
 </div>
             
            </Form>

            
          </>
        )}
     


      <div>
      {doctor && (
        <Form
          layout="vertical"
          onFinish={handleUpdatePatient}
          className="m-3"
          initialValues={{
            ...doctor,
        

             
          }}
        >
      
       
            </Form>
            )}
      </div>
      </div>
</Box>
</Box>
  );
};

export default SearchEditPatient;
