import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Badge, Divider, Box,useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MenuIcon from '@mui/icons-material/Menu';
import { adminMenu, userMenu } from '../Data/data'; // Adjust import paths as necessary
import logoImage from '../images/1.png';

const Sidebar = ({ children,open, handleDrawerToggle }) => {
    const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const handleMouseEnter = () => {
    setIsSidebarExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarExpanded(false);
  };
  const doctorMenuData = [

  
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "fa-solid fa-home",
    },
     
    {
      name: "Vaccine",
      path: "/vaccine",
      icon: "fa-solid fa-home",
    },
   
    {
      name: "Add Patient",
      path: "/AddPatient",
      icon: "fa-solid fa-paw",
    },
    // New menu item for searching patients
    {
      name: "Search Patient",
      path: "/search",
      icon: "fa-solid fa-search",
    },
     {
      name: "Profile",
      path: `/doctor/profile/${user?._id}`,
      icon: "fa-solid fa-user",
    },
 
   
  ];
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const menuIcons = {
    Dashboard: <DashboardIcon />,
    
    "Vaccine": <HealthAndSafetyIcon />,
    "Add Patient": <AddCircleOutlineIcon />,
    "Search Patient": <SearchIcon />,
    Profile: <AccountCircleIcon />,
  };

  const SidebarMenu = user?.isAdmin ? adminMenu : user?.isDoctor ? doctorMenuData : userMenu;

  return (
    <>
     
     <Drawer
         variant={isMdUp ? "permanent" : "temporary"}
      open={open}
      onClose={handleDrawerToggle}
      sx={{
        width: isMdUp ? 240 : 'auto', // Use 'auto' or specific width for mobile
        '& .MuiDrawer-paper': { width: isMdUp ? 240 : 'auto' }, // Match the width
        width: isSidebarExpanded ? 240 : 60, // Expanded width and Icon-only width
        '& .MuiDrawer-paper': {
          width: isSidebarExpanded ? 240 : 60, // Match the above width
          boxSizing: 'border-box',
          transition: 'width 0.3s', // Smooth transition for the expanding effect
        },
        '&:hover': {
          width: 240, // Width on hover
          '& .MuiDrawer-paper': { width: 240 }, // Match the above width on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        <Box
        marginTop={1}
        
          sx={{ width: 250 }}
          role="presentation"
        >
    <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '5px',
    visibility: isSidebarExpanded ? 'visible' : 'hidden', // Only show when expanded
    height: isSidebarExpanded ? 'auto' : 0, // Collapse the space when not visible
    overflow: 'hidden', // Prevent the image from overflowing when its container is collapsed
  }}
>
  <img 
    src={logoImage} 
    alt="Logo" 
    style={{ 
      maxWidth: isSidebarExpanded ? '80%' : 0, // Adjust size based on expansion state
      height: 'auto', 
      transition: 'max-width 0.3s ease-in-out', // Smooth transition for the logo size
    }} 
  />
</Box>
          <List>
            {SidebarMenu.map((menuItem, index) => (
              <ListItem button key={menuItem.name} component={Link} to={menuItem.path}>
                <ListItemIcon>
                  {menuIcons[menuItem.name] || <DashboardIcon />}
                </ListItemIcon>
                <ListItemText primary={menuItem.name} />
              </ListItem>
            ))}
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <main>{children}</main>
    </>
  );
};

export default Sidebar;
