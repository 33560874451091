
import React, { useEffect,useState } from "react";
import "../styles/RegisterStyles.css";
import { Form, Input, message } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../redux/features/alertSlice";
import logo from "../images/1.png";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  


  const handleLoginWithGoogle = () => {
    window.location.href = `https://vet.alertr.net/auth/google/callback`;
  };

  function setLocalStorage(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error("LocalStorage not accessible", e);
    }
  }
  

  
  //form handler
  const onfinishHandler = async (values) => {
    try {
      // Show loading indicator if needed
      const res = await axios.post('/api/v1/user/login', values);
      if (res.data.success) {
        localStorage.setItem('token', res.data.token); // Synchronously set token
        message.success('Login Successfully');
        setLocalStorage();
        navigate('/dashboard');
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      message.error('Something went wrong');
    } finally {
      // Hide loading indicator if previously shown
    }
  };
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  if (token) {
    setLocalStorage('token', token);
    navigate('/');
  }
}, [navigate]);

    return (
        <div className="form-container ">
          
          <Form
            layout="vertical"
            onFinish={onfinishHandler}
            className="register-form"
          >
          <div className="login-header">
          <img src={logo} alt="Logo" className="login-logo" />
          </div>
          <div>
          <h3 className="text-center">Login</h3>
        </div>
        <div className="custom-divider"></div>
    
            <Form.Item className="text-centers"   label="Email" name="email">
              <Input style={{ width: '100%' }} type="email" required />
            </Form.Item>
            <Form.Item className="text-centers" label="Password" name="password">
              <Input style={{ width: '100%' }} className="text-centers" type="password"  />
            </Form.Item>
            
            <button className="btn btn-primary" type="submit">
              Login
            </button>
           
             <p>  Not a user ?
            <Link to="/register" className="m-2">
             Register here
            </Link>
            </p>
           
          </Form>


        </div>
         );
        };

export default Login;