// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check local storage for an existing token to automatically log in the user
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (values) => {
    try {
      const response = await axios.post('/api/v1/user/login', values);
      if (response.data.success && response.data.token) {
        localStorage.setItem('token', response.data.token); // Save the token to local storage
        setIsAuthenticated(true); // Update the authentication state
        return true; // Indicate success
      } else {
        // Handle login failure
        setIsAuthenticated(false);
        return false;
      }
    } catch (error) {
      console.error("Login failed:", error);
      setIsAuthenticated(false);
      return false; // Indicate failure
    }
  };

  const logout = () => {
    localStorage.removeItem('token'); // Remove the token from local storage
    setIsAuthenticated(false); // Update the authentication state
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
