import React, { useEffect, useState } from "react";
import Layout from "./../../components/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Form, Input, Row, TimePicker, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../redux/features/alertSlice";
import moment from "moment";
import { InputNumber,Button,List,Card } from "antd";
import "./../../styles/vaccine.css";


import Sidebar from '../Sidebar';
import yourImageUrl from '../../images/allgr.png';
import { AppBar, Toolbar, InputBase, Paper, Fab, CardContent, Typography, Badge, IconButton, Box, CssBaseline, Drawer, Grid,CardActions,useTheme, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Mail as MailIcon, Menu as MenuIcon, CalendarToday as CalendarIcon, TableView as TableIcon, PersonAdd as AddPatientIcon  } from '@mui/icons-material';
const drawerWidth = 240;
const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const [doctor, setDoctor] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [displayedSlots, setDisplayedSlots] = useState(null);
  const [form] = Form.useForm();

  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("11:00");
  const [interval, setInterval] = useState(30);
  const [previewSlots, setPreviewSlots] = useState([]);



  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const modernSoothingGreen = '#029d49';


  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  

  useEffect(() => {
    if (form) {
      setStartTime(form.getFieldValue("startTime") || moment());
      setEndTime(form.getFieldValue("endTime") || moment().add(1, "hours"));
    }
  }, [form]);

  const handleViewSlots = () => {
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");
    const slots = [];

    while (startMoment.isBefore(endMoment)) {
      slots.push({
        key: slots.length + 1,
        start: startMoment.format("HH:mm"),
        end: startMoment.add(interval, "minutes").format("HH:mm"),
      });
    }

    setPreviewSlots(slots);
  };


  const handleFinish = async (values) => {
    try {
   
      dispatch(showLoading());
      const res = await axios.post(
        "/api/v1/doctor/updateProfile",
        {
          ...values,
       
          userId: user._id,
         
          timeSlots: previewSlots.map((slot) => ({
            startTime: moment(slot.start, "HH:mm").format("HH:mm"),
            endTime: moment(slot.end, "HH:mm").format("HH:mm"),
          })),
          availableSlots: {
            startTime: moment(values.startTime).format("HH:mm"),
            endTime: moment(values.endTime).format("HH:mm"),
            interval: values.interval,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/vaccine");
      } else {
        message.error(res.data.success);
      }
    } catch (error) {
      dispatch(hideLoading());
      message.error("Somthing Went Wrrong ");
    }
  };
  // update doc ==========

  //getDOc Details
  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/v1/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    //eslint-disable-next-line
  }, []);
  return (
    <Box sx={{  display: 'flex' }}>
    <CssBaseline />
    <Box sx={{

      flexGrow: 1,
      '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%', // Adjust the height as needed
        backgroundColor: '#ffffff',
        zIndex: -1
      },
      position: 'relative', // Ensure that the pseudo-element is positioned relative to this Box
    }}>
<AppBar position="fixed" elevation={0} sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent', backdropFilter: 'blur(0px)' }}>
<Toolbar sx={{ 
        display: 'flex', 
        justifyContent: 'space-between' // This will push the menu icon to the left and search bar to the right
      }}>
             <IconButton color="inherit"  onClick={handleDrawerToggle} sx={{ mr: 'auto' }}>
            <MenuIcon />
          </IconButton>
         
   
        </Toolbar>
      </AppBar>
      <Box sx={{
         flexGrow: 1,
      p: isXsDown ? 2 : 3, // Responsive padding, smaller on extra-small screens
      pb: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '30vh', // Background image heightge
        backgroundRepeat: "repeat" ,
        backgroundImage: `url(${yourImageUrl})`, // Your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1, // Behind the content
      }} />
{isMdUp ? (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
) : (
<Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
)}   <Toolbar /> 
    <div className="container">
   
    <div className="patient-form-container">
      <h1>Manage Profile</h1>
      {doctor && (
        <Form
          layout="vertical"
          onFinish={handleFinish}
          className="m-3"
          initialValues={{
            ...doctor,
            
            
          }}
        >
          <h4 className="">Personal Details : </h4>
          <Row gutter={20}>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Full Name"
                name="firstName"
                required
                rules={[{ required: true ,message:'Full name required'}]}
              >
                <Input type="text" placeholder="Your Full name" />
              </Form.Item>
            </Col>
       
            <Col xs={24} md={24} lg={8}>
            <Form.Item
    label="Phone No"
    name="clinicNumber"
    required
    rules={[{ required: true, message: 'Please input your phone number!' }]}
>
    <Input
        
        type="text"
        placeholder="Your Whatsapp No."
    /></Form.Item>
 
            </Col>
            
            <Col xs={24} md={24} lg={8}>
              <Form.Item label="Email" name="clinicemail" rules={[{ required: true }]}>
                <Input type="text" placeholder="your email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}></Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Address"
                name="address"
                required
                rules={[{ required: false }]}
              >
                <Input type="text" placeholder="your clinic address" />
              </Form.Item>
            </Col>

          
           

   
          
            
           
          
  
          
            <Col xs={24} md={24} lg={8}></Col>

            <Col xs={24} md={24} lg={8}></Col>
            <Col xs={24} md={24} lg={8}>

              <button className="btn btn-primary form-btn" type="submit">
                Update
              </button>
            </Col>
          </Row>
        </Form>
      )}
      </div>
  
    </div>
    </Box>
    </Box>
  );
};

export default Profile; 