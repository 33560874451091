import React from "react";
import Layout from "./../components/Layout";
import { Col, Form, Input, Row, TimePicker, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showLoading, hideLoading } from "../redux/features/alertSlice";
import axios from "axios";
import "./../styles/AddPatient.css";

const ApplyDoctor = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //handle form
  const handleFinish = async (values) => {
    const phoneNumberWithPrefix = `91${values.phoneNumber}`;
    try {
        dispatch(showLoading());
        const res = await axios.post(
            "/api/v1/user/apply-doctor",
            {...values,

              phoneNumber: phoneNumberWithPrefix, 
                userId: user._id},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            dispatch(hideLoading());
            dispatch(hideLoading());
            if (res.data.success) {
              message.success(res.data.message);
              navigate("/");
            } else {
              message.error(res.data.message);
            }
          } catch (error) {
            dispatch(hideLoading());
            console.log(error);
            message.error("Something Went Wrong ");
          }
        };
  
  return (
    <Layout>


<div className="patient-form-container" style={{ maxWidth: '600px', margin: '0 auto' }}>

      <h1 className="text-center">Add Profile</h1>
      <Form layout="vertical" onFinish={handleFinish} className="m-3">
        <h4 className="">Personal Details : </h4>
        <Row gutter={20}>
          <Col xs={24} md={24} lg={8}>
            <Form.Item
              label="Full Name"
              name="firstName"
              required
              rules={[{ required: true, message:'Please input your Full Name' }]}
            >
              <Input type="text" placeholder="Eg: Dr.John Oliver" />
            </Form.Item>
          </Col>
       
          <Col xs={24} md={24} lg={8}>
          <Form.Item
    label="Phone No"
    name="clinicNumber"
    required
    rules={[{ required: true, message: 'Please input your WhatsApp number!' }]}
>
    <Input
        addonBefore="91"
        type="text"
        maxLength={10} // restricts input to 10 characters
        pattern="\d*"
        placeholder="Your WhatsApp No."
    />
</Form.Item>
          </Col>
          <Col xs={24} md={24} lg={8}>
          <Form.Item
              label="Email"
              name="clinicemail"
              required
              rules={[{ required: true,message:'Please input your Email' }]}
            >
              <Input type="email" placeholder="Your Email" />
            </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
          <Form.Item
              label="Address"
              name="address"
              required
              rules={[{ required: true,message:'Please input your Address' }]}
            >
              <Input type="text" placeholder=" Your / Clinic address" />
            </Form.Item>
    </Col>
    <Col xs={24} md={24} lg={8}>
            <Form.Item
              label="Country"
              name="country"
              required
              rules={[{ required: false }]}
            >
              <Input type="text" placeholder="Your country" />
            </Form.Item>
       
      
          </Col>
          
        </Row>
      
        <Row gutter={20}>
        
        
          
          <Col xs={24} md={24} lg={8}></Col>
          <Col xs={24} md={24} lg={8}>
            <button className="btn btn-primary form-btn" type="submit">
              Submit
            </button>
          </Col>
        </Row>
      </Form>

      </div>

    </Layout>
  );
};

export default ApplyDoctor;