import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import Layout from '../../components/Layout';


const MessageDataComponent = () => {
  const [messageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    axios.post(
      "/api/v1/doctor/getDoctorInfo",
      { userId: params.id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.success) {
        const doctor = res.data.data;
        console.log('Doctor info:', doctor);

        return axios.get(`/api/v1/user/message?clinicemail=${doctor.clinicemail}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } else {
        throw new Error('Failed to fetch doctor info');
      }
    })
    .then((response) => {
      if (response.data.success) {
        setMessageData(response.data.data.monthlyCounts);
        console.log('Message data:', response.data.data.monthlyCounts);
      } else {
        throw new Error('Failed to fetch message data');
      }
    })
    .catch((error) => {
      console.error('Error:', error.message);
      setError(error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [params.id]);

  return (
    <Layout>
    <div>
      <h1>Message Data</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <>
          {messageData.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Year</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Month</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Total Messages</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Successful Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {messageData.map((monthData, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                      <td style={{ padding: "8px" }}>{monthData.year}</td>
                      <td style={{ padding: "8px" }}>{monthData.month}</td>
                      <td style={{ padding: "8px" }}>{monthData.count}</td>
                      <td style={{ padding: "8px" }}>{monthData.successfulNumbers.join(', ')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No message data available.</p>
          )}
        </>
      )}
    </div>
    </Layout>
  );
}

export default MessageDataComponent;
