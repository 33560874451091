import React, { useState,useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/AppointmentSearch.css';
const AppointmentSearch = ({ doctorId: initialDoctorId, updateAvailableTimeSlots,getUserData }) => {
  const [doctorId, setDoctorId] = useState(initialDoctorId || '');
  const [bookingDate, setBookingDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  const fetchTimeSlots = async (date) => {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const response = await axios.get(`/api/path-to-fetch-time-slots?doctorId=${doctorId}&date=${formattedDate}`);
      setAvailableTimeSlots(response.data.timeSlots || []);
    } catch (error) {
      console.error("Error fetching time slots:", error);
      setAvailableTimeSlots([]);
    }
  };

  const handleSearch = async () => {
    try {
      await getUserData();
      const formattedDate = bookingDate.toISOString().split('T')[0];
      const response = await axios.get(`/api/v1/user/appointments?doctorId=${doctorId}&bookingDate=${formattedDate}`);
      await getUserData();
      setAppointments(response.data);

      const timeSlotsResponse = await axios.get(`/api/v1/doctor/time-slots?doctorId=${doctorId}&date=${formattedDate}`);
      const completeTimeSlots = timeSlotsResponse.data;

      // Pass the booked timings to the callback
      updateAvailableTimeSlots(response.data.map(appointment => appointment.timings.startTime));
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };


  useEffect(() => {
    // Fetch time slots whenever the date changes
    fetchTimeSlots(bookingDate);
  }, [bookingDate, doctorId]);

  return (
    <div className="appointment-search-container">
       <div className="input-group date-input">
      <DatePicker selected={bookingDate} onChange={(date) => setBookingDate(date)} />
      <label className="date-label">Select the date </label></div>
      <div className="input-group doctor-id-input">
        <input type="text" value={doctorId} onChange={(e) => setDoctorId(e.target.value)} />
      </div>
     
      <div className="input-group search-button-container">
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
    </div>
  );
};

export default AppointmentSearch;