import React from 'react';

function PaymentComponent() {
    const htmlPageUrl = './public/dataFrom.html'; // Adjust the path as needed

    return (
        <iframe 
            src={htmlPageUrl} 
            title="Payment Page" 
            width="100%" 
            height="600px" // Adjust size as needed
        ></iframe>
    );
}

export default PaymentComponent;
