import React, { useEffect,useState } from "react";
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, InputBase, Paper, Fab, Card, CardContent, Typography, Badge, IconButton, Box, CssBaseline, Drawer, Grid,CardActions,useTheme, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Mail as MailIcon, Menu as MenuIcon, CalendarToday as CalendarIcon, TableView as TableIcon, PersonAdd as AddPatientIcon  } from '@mui/icons-material';
import yourAdImageUrl from '../../images/COVER.png';
import yourImageUrl from '../../images/allgr.png';
import Sidebar from '../Sidebar';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SpeedIcon from '@mui/icons-material/Speed';

import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
const drawerWidth = 240;

function Dashboard() {
 const navigate = useNavigate(); // for React Router v6
  const { user } = useSelector((state) => state.user);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const [clinicNumber, setClinicNumber] = useState('');
  const [clinicemail, setClinicemail] = useState('');
  const [doctor, setDoctor] = useState(null);
  const params = useParams();
  const [messageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    setLoading(true);
    axios.post(
      "/api/v1/doctor/getDoctorInfo",
      { userId: params.id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.success) {
        const doctor = res.data.data;
        console.log('Doctor info:', doctor);

        return axios.get(`/api/v1/user/message?clinicemail=${doctor.clinicemail}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } else {
        throw new Error('Failed to fetch doctor info');
      }
    })
    .then((response) => {
      if (response.data.success) {
        setMessageData(response.data.data.monthlyCounts);
        console.log('Message data:', response.data.data.monthlyCounts);
      } else {
        throw new Error('Failed to fetch message data');
      }
    })
    .catch((error) => {
      console.error('Error:', error.message);
      setError(error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [params.id]);



  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
 
  const onCardClick = (path) => {
    // history.push(path); // for React Router v5
    navigate(path); // for React Router v6
  };

  const onFabClick = () => {
    // history.push('/patient'); // for React Router v5
   navigate('/AddPatient'); // for React Router v6
  };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const modernSoothingGreen = '#029d49';


  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


  const paperStyle = {
    backgroundImage: `url(${yourAdImageUrl})`,
    backgroundSize: 'cover', // Ensure the image covers the entire Paper area
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    // Remove fixed height and set height to auto to let the content dictate the height
    height: 'auto',
    minHeight: '470px', 
    mb: 4,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      // Apply shadow and border radius as desired
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.6)',
      borderRadius: 5,
      // Remove or adjust padding and margin that might be creating extra space
      padding: 0,
      margin: 0,
      minHeight: '160px', 
      // Set maxWidth to 100% to ensure it fills the width
      maxWidth: '100%',
    }
  };
  const cardData = [
    {
      title: 'View Vaccination dates ',
      value: 'Calender',
      icon: <CalendarIcon />,
      navigateTo: '/vaccine',
      percentage: '3.48%',
      bgColor: 'linear-gradient(145deg, #fed304, #fed304);', // Dark gradient
    },
    {
      title: 'Add New Pet Details',
      value: 'Add Patient',
      navigateTo: '/AddPatient',
      icon: <AddPatientIcon />,
      percentage: '3.48%',
      color:'white',
      bgColor: 'linear-gradient(145deg, #ffffff, #ffffff)', // Purple gradient
    },
    {
      title: 'Detailed List with Dates ',
      value: 'Table',
      icon: <TableIcon />,
      navigateTo: '/vaccine',
      percentage: '3.48%',
  
      bgColor: 'linear-gradient(145deg, #029d49, #029d49)', // Red gradient
    },
  ];
  
  const MessageDataCard = ({ data }) => {
    const monthName = monthNames[data.month - 1]; 
    return (
      <Card sx={{ 
        minWidth: 280,
        mb: 4, mt: -1,
        background: 'linear-gradient(145deg, #202020, #161616)', // Soft blue to purple gradient
       color:'white',
        boxShadow: '0 4px 32px 0 rgba(31, 38, 135, 0.37)', // Soft, glowing shadow
        borderRadius: '15px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center', // Center text alignment
        '&:hover': {
          transform: 'translateY(-5px)', // Subtle lift effect on hover
          boxShadow: '0 15px 35px rgba(31, 38, 135, 0.25)', // Enhanced shadow on hover
        },
      }}>
        <Typography variant="h6" component="div" sx={{ mb: 1 }}>
        {` ${data.year} | ${monthName}`}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }}>
          {`Total Messages: ${data.count}`}
        </Typography>
      </Card>
    );
  };
  
  
  
  // Then, within your Dashboard component, call this new component for each message data item.

  
  const ModernCard = ({ card, navigateTo }) => {
    return (
      <Card sx={{ 
            padding: theme.spacing(2), // Use theme's spacing for consistency
  margin: theme.spacing(2), 
        minWidth: 275,
        color: '#333333',
        backgroundImage: card.bgColor,
        borderRadius: '20px',
        position: 'relative',
        overflow: 'visible',
        boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.2)',
        marginBottom: '20px',
        marginTop: '20px'
      }}
      onClick={() => onCardClick(navigateTo)} >
        <CardContent
         sx={{
          
        
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: '100%'
        }}   >
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            {card.title}
          </Typography>
          <Typography variant="h5" component="div">
            {card.value}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="white" >
    
          </Typography>
          <Box sx={{
                padding: theme.spacing(2), // Use theme's spacing for consistency
  margin: theme.spacing(2), 
  p: isXsDown ? 1 : 2, // Smaller padding on extra small screens
  m: isXsDown ? 1 : 2, 
 
            position: 'absolute',
            top: '20px',
            right: '1px',
            bgcolor: 'white',
            borderRadius: '50%',
            padding: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
              fontSize: '40px',
              color: 'Green',
            },
            [theme.breakpoints.down('xs')]: {
      top: '10px', // Adjust top position for mobile
      right: '10px', // Adjust right position for mobile
      '& svg': {
        fontSize: '20px', // Adjust icon size for mobile
      }
    }
          }}>
            {card.icon}
          </Box>
        </CardContent>
      </Card>
    );
  };
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{
 
        flexGrow: 1,
        '::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '50vh', // Adjust the height as needed
          backgroundColor: modernSoothingGreen,
          zIndex: -1
        },
        position: 'relative', // Ensure that the pseudo-element is positioned relative to this Box
      }}>
  <AppBar position="fixed" elevation={0} sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent', backdropFilter: 'blur(0px)' }}>
  <Toolbar sx={{ 
          display: 'flex', 
          justifyContent: 'space-between' // This will push the menu icon to the left and search bar to the right
        }}>
               <IconButton color="inherit"  onClick={handleDrawerToggle} sx={{ mr: 'auto' }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h4" sx={{ 
    mb: 4, 
fontWeight:'900',
    background: 'white',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1rem',
  }}>
{user?.name}
  </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{
           flexGrow: 1,
        p: isXsDown ? 2 : 3, // Responsive padding, smaller on extra-small screens
        pb: 0,
          position: 'absolute',
          top: 0,
   
          left: 0,
          width: '100%',
          height: '50vh', // Background image height
          backgroundImage: `url(${yourImageUrl})`, // Your background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: -1, // Behind the content
        }} />
{isMdUp ? (
  <Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
) : (
  <Sidebar drawerWidth={drawerWidth} open={isSidebarOpen} handleDrawerToggle={handleDrawerToggle} />
)}   <Toolbar /> {/* This Toolbar is necessary to offset the content below the AppBar */}
        
        <Box elevation={0} sx={{
  backgroundColor: 'transparent',
  
  flexGrow: 1,
  pr: isXsDown ? 1 : 2, 
      pl: isXsDown ? 1 : 2, // Smaller padding on extra small screens
      pb: 0, // No padding at the bottom, adjust as needed
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centers Paper horizontally
          justifyContent: 'flex-start', // Aligns Paper right below AppBar
       
        }}>
          <Paper elevation={1} sx={paperStyle} />
        <Fab onClick={() => onFabClick('/AddPatient')}  sx={{
    position: 'fixed',
    right: 20,
    bottom: 20,
    color: 'white',
    backgroundColor: theme.palette.success.main, // using theme's success color
    '&:hover': {
      backgroundColor: theme.palette.success.dark, // darker on hover
    }
  }}>
          <SearchIcon onClick={() => onFabClick('/search')} />
        </Fab>

        <Grid container spacing={{ xs: 2, sm: 4 }} justifyContent="center">
  {cardData.map((card, index) => (
    <Grid item key={index} xs={12} sm={6} md={3} lg={3}>
      <ModernCard navigateTo={card.navigateTo} card={card} />
    </Grid>
  ))}
</Grid>

        <Box sx={{ mt: 6, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Typography variant="h4" sx={{ 
    mb: 4, 
    fontWeight: '600',
    background: 'linear-gradient(to right, #202020 0%, #1B1C1C 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '2rem',
  }}>
    Message Analytics
  </Typography>
  <Grid container spacing={2} justifyContent="center">
    {messageData.length > 0 ? (
      messageData.map((data, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <MessageDataCard data={data} />
        </Grid>
      ))
    ) : (
      <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#6a11cb' }}>
        No message data available.
      </Typography>
    )}
  </Grid>
</Box>


          
     
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
