import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";

import Register from "./pages/Register";
import { useSelector } from "react-redux";
import Spinner from "./components/Spinner";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ApplyDoctor from "./pages/ApplyDoctor";
import NotificationPage from "./pages/NotificationPage";
import Users from "./pages/admin/Users";
import Doctors from "./pages/admin/Doctors";
import Profile from "./pages/doctor/Profile";
import BookingPage from "./pages/BookingPage";
import Appointments from "./pages/Appointments";
import DoctorAppointments from "./pages/doctor/DoctorAppointments";
import PaymentComponent from "./iframe/PaymentPage";

import AddPatient from "./pages/doctor/patient";
import SearchEditPatient from "./pages/doctor/patientsearch";
import VaccineReview from "./pages/doctor/vaccineretrieve";
import GoogleAuthRedirect from "./components/GoogleAuthRedirect";
import Front from "./pages/Front";
import axios from 'axios';
import './App.css';
import MessageDataComponent from "./pages/doctor/analysis";
import Dashboard from "./pages/doctor/dashboard";
import Login2 from "./pages/login2";

function App() {
  
  axios.defaults.withCredentials = true;
  
  const { loading } = useSelector((state) => state.alerts);
  return (
    <>
    
      <BrowserRouter>
        {loading ? (
          <Spinner />
        ) : (
          <Routes>
            <Route
              path="/apply-doctor"
              element={
                <ProtectedRoute>
                  <ApplyDoctor />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/doctors"
              element={
                <ProtectedRoute>
                  <Doctors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctor/profile/:id"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AddPatient"
              element={
                <ProtectedRoute>
                  <AddPatient />
                </ProtectedRoute>
              }
            />

             <Route
              path="/search"
              element={
                <ProtectedRoute>
                  <SearchEditPatient />
                </ProtectedRoute>
              }
            />
               <Route
              path="/home-1"
              element={
                <ProtectedRoute>
                  <Front />
                </ProtectedRoute>
              }
            />

                <Route
              path="/vaccine"
              element={
                <ProtectedRoute>
                  <VaccineReview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctor/book-appointment/:doctorId"
              element={
                <ProtectedRoute>
                  <BookingPage />
                </ProtectedRoute>
              }
            />



     

            <Route
              path="/notification"
              element={
                <ProtectedRoute>
                  <NotificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
                <Route
              path="/login2"
              element={
                <PublicRoute>
                  <Login2 />
                </PublicRoute>
              }
            />
            
            
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
              <Route path="/google-auth-redirect" element={<GoogleAuthRedirect />} />
            <Route
              path="/appointments"
              element={
                <ProtectedRoute>
                  <Appointments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctor-appointments"
              element={
                <ProtectedRoute>
                  <DoctorAppointments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentComponent />
                </ProtectedRoute>
              }
            />

             <Route
              path="/analysis"
              element={
                <ProtectedRoute>
                  <MessageDataComponent />
                </ProtectedRoute>
              }
            />


<Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;