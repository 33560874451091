import React from 'react'
import { Form, Input, message } from "antd";
import "../styles/RegisterStyles.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../redux/features/alertSlice";
import logo from "../images/1.png";

const Register = () => {
  const dispatch = useDispatch();

const navigate = useNavigate();
  const onfinishHandler = async (values) => {
 try {
  dispatch(showLoading());
      const res = await axios.post('/api/v1/user/register', values);
      dispatch(hideLoading());
      if (res.data.success) {
        message.success("Register Successfully!");
        navigate("/login");
      } else {
        message.error(res.data.message);
      }
    
  } catch (error) {
    dispatch(hideLoading());
    console.log(error);
    message.error("Something Went Wrong");
  }
};
  return (
    <>
    <div className="form-container ">
        <Form
          layout="vertical"
          onFinish={onfinishHandler}
          className="register-form"
        >
            <div className="logo">
         
         <img src={logo} alt="Name"  className="login-logo " />
   
       
               <hr />
             </div>

         <h3 className="text-center">Register From</h3>
         <Form.Item label="Full Name" name="name">
            <Input style={{ width: '100%' }}  type="text"  placeholder="Eg:Dr.John Oliver" required />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input style={{ width: '100%' }} type="email" required />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input style={{ width: '100%' }} type="password" required />
          </Form.Item>
      
          <button className="btn btn-primary" type="submit">
            Register
          </button>
          <p>  Already user ?
          <Link to="/login" className="m-2">
           Login here
          </Link></p>
          </Form>

        </div>
        </>
  )
}

export default Register